import { 
  forwardRef, 
  useImperativeHandle, 
  useState,
} from "react";
import { Modal, Toast } from "antd-mobile";
import dayjs from "dayjs";
import CloseIcon from '@/assets/images/icon_close.png'
import DateIcon from '@/assets/images/icon_sign_date.png'
import "./index.less";

const DateSign = forwardRef((
  {
    data: {
      signingList,
    },
    operations: {
      setSigningList,
      handleScrollIntoView,
    }
  },
  ref,
) => {
  const [visible, setVisible] = useState(false)

  useImperativeHandle(
    ref,
    () => ({
      open: async _ => {
        const target = signingList 
          ?.filter(it => !it.signValue && isDateWidget(it))
          ?.[0]
        
        if (target) {
          handleScrollIntoView(`#signing-widget-${target.id}`)

          setTimeout(() => {
            setVisible(true)
          }, 650);
        }
      },
      close: _ => handleClose(),
    }),
  )

  const handleClose = _ => {
    setVisible(false)
  }

  const isDateWidget = it => [3].includes(it.widgetType) // widgetType -> 3-签署日期

  const handleSign = type => {
    const target = signingList // 获取签字类型下第一个待签署控件
      ?.filter(it => !it.signValue && isDateWidget(it)) // widgetType -> 2 经办人签字
      ?.[0]

    const nextSigningList = signingList.map(it =>
      it.id === target?.id
        ? {
          ...it, 
          signValue: {
            1: dayjs().format('YYYY-MM-DD'),
            2: dayjs().format('YYYY年MM月DD日'),
          }[type],
        }
        : it
    )
    setSigningList(nextSigningList)
    handleClose()

    const isComplete = nextSigningList
      .filter(it => isDateWidget(it))
      .every(it => it.signValue)
    const nextTarget = nextSigningList
      .filter(it => !it.signValue && isDateWidget(it))?.[0]
    isComplete
      ? Toast.info('已完成日期签署', 1.2)
      : handleScrollIntoView(`#signing-widget-${nextTarget.id}`)
  }

  return (
    <Modal
      visible={visible}
      maskClosable={false}
      wrapClassName="date-sign-modal-wrapper"
      transparent
    >
      <section className="date-sign-modal flex-c">
        <img src={CloseIcon} onClick={handleClose} className="icon-24 icon-close" alt="" />

        <div className="title flex-center">
          <img src={DateIcon} className="icon-24" alt="" />
          <span className="ml-4">使用签署日期签署</span>
        </div>

        <div className="sign-count mt-4">
          共{signingList?.filter(it => isDateWidget(it))?.length ?? '-'}处，
          已签章{signingList?.filter(it => it.signValue && isDateWidget(it))?.length}处
        </div>

        <div className="sign-item mt-12 flex-c">
          <button 
            style={{justifyContent: 'center'}} 
            className="btn-date flex-1"
            onClick={() => handleSign(1)}
          >
            {dayjs().format('YYYY-MM-DD')}
          </button>
          <button 
            style={{justifyContent: 'center'}} 
            className="btn-date flex-1 mt-16"
            onClick={() => handleSign(2)}
          >
            {dayjs().format('YYYY年MM月DD日')}
          </button>
        </div>
      </section>
    </Modal>
  );
});

export default DateSign;
