import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getContractDetail, getUserInfo, getSeal } from "../../../apis/previous";

function useSearchContract() {
  const params = useParams();
  const { info } = params;
  const { id, token,contractType,companyJoinVerifyId } = JSON.parse(info);
  sessionStorage.token = token;

  const [pdfUrl, setPdfUrl] = useState("");

  const [phone, setPhone] = useState("");

  const [realName, setRealName] = useState("");

  const [voList, setVoList] = useState([]);

  const [isSetPwd, setIsSetPwd] = useState(false);

  const [sealList, setSealList] = useState([]);

  const [infoLoaded, setInfoLoaded] = useState(false);

  const [userId, setUserId] = useState("");
  const [sourceType, setSourceType] = useState(0);

  const handleContractDetail = async () => {
    const {
      data: { localUrl, phone, realName, voList, id: userId, sourceType },
    } = await getContractDetail(id);

    setPdfUrl(localUrl);
    setPhone(phone);
    setRealName(realName);
    setVoList(voList);
    setUserId(userId);
    setSourceType(sourceType);
    return phone;
  };

  const handleGetUserInfo = async (phone) => {
    const {
      data: { isSecPwd },
    } = await getUserInfo(phone);
    setIsSetPwd(isSecPwd);
  };

  const handleGetSeal = async () => {
    const { data: sealList } = await getSeal();
    setSealList(sealList || []);
  };

  useEffect(() => {
    const handleGetContractDetail = async () => {
      const phone = await handleContractDetail();
      await Promise.all([handleGetUserInfo(phone), handleGetSeal()]);
      setInfoLoaded(true);
    };
    handleGetContractDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return {
    pdfUrl,
    phone,
    realName,
    voList,
    id,
    isSetPwd,
    sealList,
    infoLoaded,
    userId,
    sourceType,
    contractType, // 1 是合同签署，4 是授权签署  5 划石社区合同模板
    companyJoinVerifyId
  };
}

export { useSearchContract };
