import React, { useMemo, useEffect, useContext } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { SignBoardContext } from '../../context'
import Header from '../Header'
import SignatureIcon from '@/assets/images/icon_widget_signature.png'
import PersonDateIcon from '@/assets/images/icon_widget_date_1.png'
import SealIcon from '@/assets/images/icon_widget_seal.png'
import "./index.less";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker_2.6.js`;

const getWidgetInfo = (
  it, 
  signInfo, 
  a4Rect,
) => { 
  if (!Object.keys(signInfo).length) return {}
  const adaptRatio = 0.3 // 此处适配比例根据显示效果进行动态调整
  const ratio = (window.innerWidth / a4Rect.w) + adaptRatio
  const style = [2, 3].includes(it.widgetType) // 控件类型 1-企业盖章 2-经办人签字 3-签署日期 4-骑缝章
    ? {
      width: `${ratio * 102}px`, 
      height: `${ratio * 48}px`,
    }
    : {
      width: `${ratio * 96}px`, 
      height: `${ratio * 96}px`,
    }

  return {
    1: { // 个人
      1: {
        text: '企业盖章',
        icon: SealIcon,
        style,
      },
      2: {
        text: '个人签名',
        icon: SignatureIcon,
        style,
      },
      3: {
        text: '签署日期',
        icon: PersonDateIcon,
        style,
      },
      4: {
        text: '骑缝章',
        icon: SealIcon,
        style,
      },
    },
    2: { // 企业
      1: {
        text: '企业盖章',
        icon: SealIcon,
        style,
      },
      2: {
        text: '个人签名',
        icon: SignatureIcon,
        style,
      },
      3: {
        text: '签署日期',
        icon: PersonDateIcon,
        style,
      },
      4: {
        text: '骑缝章',
        icon: SealIcon,
        style,
      },
    },
  }[signInfo?.signatoryType][it.widgetType]
}

// 已签署控件列表 直接渲染内容
const SignedContent = ({
  calcSignedList,
  signInfo,
  a4Rect,
}) => {
  const widgetClassName = it => it // 此处暂时根据控件类型判断 签署类型 1-经办人签字(个人、经办人) 2-印章管理员盖章(企业)
    ? {
      1: 'enterprise',
      2: 'person',
      3: 'person',
      4: 'enterprise',
    }[it.widgetType]
    : ''
  const ratio = window.innerWidth / a4Rect.w

  const renderSigningItem = it => {
    const isSignature = it.widgetType === 2 && it.isSignature
    const formatPhone = phone => phone
      ? phone.replace(/^(\d{3})(\d{4})(\d{4})$/g, '$1****$3')
      : '-'
    
    return (
      <>
        <div style={{flexShrink: 0}} className="sign-widget-header text-ellipse">
          <span>{it.signatoryUserName || '-'}</span>
          <span className="ml-6">({formatPhone(it.signatoryPhone)})</span>
        </div>

        <div style={{borderColor: 'transparent'}} className="sign-widget-content flex-center">
          {
            getWidgetInfo(it, signInfo, a4Rect)?.style && (
              it.widgetType === 3 // 签署日期
                ? <span className="date">{it.signValue || '-'}</span>
                : <img
                  src={it.signValue}
                  style={getWidgetInfo(it, signInfo, a4Rect)?.style}
                  className={isSignature ? 'signature' : ''}
                  alt=""
                />
            )
          }
        </div>
      </>
    )
  }

  return calcSignedList?.map((it, idx) => {
    return (
      <div
        key={idx}
        id={`signed-widget-${it.id}`}
        className={`sign-widget flex-c ${widgetClassName(it)}`}
        style={{ 
          top: it.top, 
          left: it.left, 
          width: it.widgetCoordinateDto?.width,
          height: it.widgetCoordinateDto?.height,
          transform: `scale(${ratio + 0.2})`,
        }}
      >
        {renderSigningItem(it)}
      </div>
    )
  }) 
}

// 待签收控件列表
const SigningContent = ({
  calcSigningList,
  signInfo,
  a4Rect,
}) => {
  const widgetClassName = signInfo?.signatoryType > 0 // 签署类型 1-经办人签字(个人、经办人) 2-印章管理员盖章(企业)
    ? {
      1: 'person',
      2: 'enterprise',
    }[signInfo?.signatoryType]
    : ''
  const ratio = window.innerWidth / a4Rect.w

  const renderSigningItem = it => {
    const isSignature = it.widgetType === 2 && it.isSignature
    const formatPhone = phone => phone
      ? phone.replace(/^(\d{3})(\d{4})(\d{4})$/g, '$1****$3')
      : '-'
    
    return (
      <>
        <div style={{flexShrink: 0}} className="sign-widget-header text-ellipse">
          <span>{it.signatoryUserName || '-'}</span>
          <span className="ml-6">({formatPhone(it.signatoryPhone)})</span>
        </div>

        <div className="sign-widget-content flex-center">
          {
            it.signValue
              ? (
                <>
                  {
                    getWidgetInfo(it, signInfo, a4Rect)?.style && (
                      it.widgetType === 3
                        ? <span className="date">{it.signValue}</span>
                        : (
                          <img
                            src={it.signValue}
                            style={getWidgetInfo(it, signInfo, a4Rect)?.style}
                            className={isSignature ? 'signature' : ''}
                            alt=""
                          />
                        )
                    )
                  }
                </>
              )
              : (
                <div 
                  className="sign-widget-content-type flex-center"
                  style={{
                    bottom: ratio * 8,
                    right: ratio * 8,
                  }}
                >
                  <img src={getWidgetInfo(it, signInfo, a4Rect)?.icon} className="icon-16" alt="" />
                  <span className="ml-4">{getWidgetInfo(it, signInfo, a4Rect)?.text}</span>
                </div>
              )
          }
        </div>
      </>
    )
  }

  return calcSigningList?.map((it, idx) => {
    return (
      <div
        key={idx}
        id={`signing-widget-${it.id}`}
        className={`sign-widget flex-c ${widgetClassName}`}
        style={{ 
          top: it.top, 
          left: it.left, 
          width: it.widgetCoordinateDto?.width,
          height: it.widgetCoordinateDto?.height,
          transform: `scale(${ratio + 0.2})`,
        }}
      >
        {renderSigningItem(it)}
      </div>
    )
  }) 
}

// 合同PDF
const PdfContent = ({
  documentList,
  setTotalPage,
  getPageNum,
  getTotalPageNum,
  handleLoaded,
}) => {
  return documentList?.map((doc, docIdx) => (
    <Document
      key={doc.id}
      file={doc.documentUrl}
      loading={docIdx === 0 && 'pdf加载中'}
      noData={docIdx === 0 && 'pdf加载中'}
      renderMode="canvas"
      onLoadSuccess={() => setTotalPage(getTotalPageNum(documentList))}
      onLoadError={e => console.log(e)}
    >
      {doc.imageList?.map((page, pageIdx) => (
        <div key={page.id} id={`document-page-${getPageNum(documentList, page.id)}`}>
          <Page
            pageNumber={pageIdx + 1}
            loading=""
            width={window.innerWidth}
            onLoadSuccess={() => getPageNum(documentList, page.id) === getTotalPageNum(documentList) && handleLoaded()}
          />
        </div>
      ))}
    </Document>
  ))
}

const PageView = ({
  data: {
    pdfPageRef,
    documentList, // 文档列表
    signedList, // 已签署控件列表
    signingList, // 待签署控件列表
    signInfo, // 签署节点信息
    contractType, // 4 授权签署  5划石社区合同模板
    a4Rect, // A4尺寸
  },
  operations: {
    setTotalPage,
    handleLoaded,
  },
}) => {
  const {
    _commonOperations: {
      getPageNum,
      calcPageNum,
      getTotalPageNum,
    },
  } = useContext(SignBoardContext)

  // 计算渲染坐标后的待签署/已签署 控件列表
  const { 
    calcSignedList, 
    calcSigningList, 
  } = useMemo(() => {
    const getCalcList = list => list.map(it => {
      const {
        documentId,
        documentPage,
        widgetCoordinateDto: {
          horizontal: widgetLeft,
          vertical: widgetTop,
          width: widgetWidth,
          height: widgetHeight,
        } = {},
      } = it

      const ratio = window.innerWidth / a4Rect.w
      const pageNum = calcPageNum(
        documentList, 
        documentId, 
        documentPage,
      )
      const { 
        height: pageHeight, 
      } = document
        .querySelector(`#document-page-${pageNum}`)
        ?.getBoundingClientRect() 
        || {}
      const { 
        height: headerHeight, 
      } = document
        .querySelector(`.sign-header`)
        ?.getBoundingClientRect() 
        || {}
      
      return {
        ...it,
        widgetWidth,
        widgetHeight,
        left: Math.min(
          ratio * widgetLeft,
          window.innerWidth - (ratio + 0.2) * widgetWidth, // 为确保不出现X轴滚动 left 最大不超过屏幕宽度 - 缩放后控件宽度 
        ),
        top: pageHeight 
          ? headerHeight + ((pageNum - 1) * pageHeight) + ((pageHeight / a4Rect.h) * widgetTop)
          : 0, 
      }
    })
    
    return {
      calcSignedList: getCalcList(signedList),
      calcSigningList: getCalcList(signingList),
    }
  }, [signedList, signingList, calcPageNum, documentList, a4Rect.w, a4Rect.h])

  // 监听到signingList完成所有签署后，滚动至签署的第一处
  useEffect(() => {
    if (!signingList.every(it => it.signValue)) return
    window.scrollTo({ // 使页面滚动至盖章处居中位置
      top: signingList[0]?.top - window.innerHeight / 2,
      behavior: 'smooth'
    })
  }, [signingList])

  return (
    <div ref={pdfPageRef} id="pdfView">
      <Header 
        documentList={documentList} 
        signInfo={signInfo}
      />
      {/* TODO?: 如当前节点已签署控件现在已被直接展示在PDF中 将不进行不渲染 */}
      <SignedContent 
        calcSignedList={calcSignedList}
        signInfo={signInfo}
        a4Rect={a4Rect}
      />
      <SigningContent
        calcSigningList={calcSigningList}
        signInfo={signInfo}
        a4Rect={a4Rect}
      />
      <PdfContent 
        documentList={documentList} 
        setTotalPage={setTotalPage} 
        getTotalPageNum={getTotalPageNum} 
        getPageNum={getPageNum} 
        handleLoaded={handleLoaded} 
      />
    </div>
  );
};

export default PageView;
