import Axios from 'axios'
import { Toast } from "antd-mobile";
import { BASE_URL } from '@/config'

const axios = Axios.create()

axios.interceptors.request.use(config => {
	sessionStorage.token
		&& (config.headers.Authorization = `Bearer ${sessionStorage.token}`)
	sessionStorage.companyId
		&& (config.headers.companyId = sessionStorage.companyId)
	sessionStorage.companyUserId
		&& (config.headers.companyUserId = sessionStorage.companyUserId)

	// config.headers.Authorization = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsaWNlbnNlIjoiTUFERSBCWSBVT0tPIiwiaWRlbnRpZmllciI6IjE4MjAyNTAzMjkxIiwidXNlcl9uYW1lIjoiMTgyMDI1MDMyOTEiLCJzY29wZSI6WyJzZXJ2ZXIiXSwiZXhwIjoxNjgwNTk0OTYyLCJ1c2VySWQiOiI2MjI5NTM3MTE2OTQyMjEzMTIiLCJhdXRob3JpdGllcyI6WyJST0xFX1VTRVIiLCJSRU1PVEVfU0lHTklORyJdLCJqdGkiOiI5OWEzYTIyYS1hM2JjLTQ4ODktOTc1Yy1hYmFkMDIyZTJiZjMiLCJhdXRoSWQiOiI2MjI5NTM3MTE5MzMyOTY2NDAiLCJjbGllbnRfaWQiOiJyZW1vdGVfc2lnbmluZyJ9._nV_-h7ZKlfUh08yAmB_3G0b7RO9YErw6VR_6XSx2Yc'
	// config.headers.companyId = '622953715124183040'
	// config.headers.companyUserId = '622953715199680512'

	return config
})

axios.interceptors.response.use(
	res => {
		return new Promise((resolve, reject) => {
			const { data } = res

			if (data?.code === '000') {
				resolve(data)
			}
			else if (data?.code === '20001') {
				reject(data)
				Toast.fail('登录已过期')
			}
			else {
				Toast.fail(data?.msg ?? data?.message ?? res?.message ?? res?.errMsg ?? '请求错误')
				reject(data)
			}
		})
	},
	error => {
		const res = error.response?.data
		const msg = res?.msg ?? res?.message ?? error?.message ?? '请求错误'
		Toast.fail(msg)
		return Promise.reject(msg)
	}
)

export default axios