import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import html2canvas from 'html2canvas'
import { Toast } from 'antd-mobile'
import { base64Upload } from '@/utils/base64Upload'
import { addSeal } from '@/apis'

function useDrawSeal(element, dev) {
    const history = useHistory()

    useLayoutEffect(() => {
        if (!element.offsetWidth) return
        Toast.loading('生成印章中')
        html2canvas(element, {
            allowTaint: true,
            logging: true,
            dpi: 350,
            backgroundColor: "transparent"
        }).then(async canvas => {
            const imgBase64 = canvas.toDataURL('image/png');
            const url = await base64Upload(imgBase64)
            await addSeal(url)
            // 如果是在签署时点击的生成印章则直接回退
            if (dev === 'web') {
                Toast.success('生成成功,即将返回至合同签署页...', 1)
                setTimeout(() => history.goBack(), 1000)
            } else {
                window.wx.miniProgram.postMessage({ data: { url } })
                window.wx.miniProgram.navigateBack()
            }
        })

    }, [element, dev, history])
}

export { useDrawSeal }