import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import './index.less'

const SingleAutoFocusIpt = ({
	type = "text",
	autoFocus = false,
	length = '0',
	scrollWhenFocus = true,
	sensitive = false,
	codeChange = () => { }
}) => {
	if (isNaN(+length)) throw new Error("length必须为number或纯数字的字符串");

	const [value, setValue] = useState('')

	const [tempScroll, setTempScroll] = useState(document.body.scrollTop || document.documentElement.scrollTop)

	const [iptsOptions, setIptsOptions] = useState(
		Array.from({ length }).map(() => ({ focus: false }))
	)

	const iptRef = useRef({})

	// 处理应该是第几个input框显示光标
	const handleViewFocus = () => {
		setIptsOptions(prev => {
			const focusIndex = value.length >= length
				? value.length - 1
				: value.length
			prev.forEach((item, index) => item.focus = index === focusIndex);
			return [...prev]
		})
	}

	useEffect(() => {
		codeChange({
			value,
			fulfill: +value.length >= +length
		})

		// 如果输入完成了则自动失焦
		if (+value.length >= +length) {
			iptRef.current.blur()
		} else {
			// 否则则处理view的光标
			handleViewFocus();
		}
	}, [value]) // eslint-disable-line react-hooks/exhaustive-deps

	useLayoutEffect(() => {
		autoFocus ? iptRef.current.focus() : iptRef.current.blur()
	}, [autoFocus])

	const handleBlur = () => {
		window.scrollTo(0, tempScroll)
		setIptsOptions(prev => prev.map(it => ({ ...it, focus: false, })))
	}

	const handleFocus = () => {
		if (scrollWhenFocus) {
			const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
			setTempScroll(scrollTop)
			window.scrollTo(0, document.body.offsetHeight)
		}

		handleViewFocus();
	}

	const handleInput = ({ target: { value } }) => {
		setValue(value)
	}

	const handleClick = () => {
		iptRef.current.focus()
	}

	return (<div className="single-auto-focus-ipt">
		<input
			ref={iptRef}
			className="val-ipt"
			type={type}
			value={value}
			maxLength={length}
			onChange={handleInput}
			onBlur={handleBlur}
			onFocus={handleFocus}
		/>
		{iptsOptions.map((item, index) => (<div className="ipt-box" key={index}>
			<div className="ipt-value" onClick={handleClick}>
				{
					sensitive
						? value[index] 
							? '*' 
							: ''
						: value[index] 
				}
				{item.focus && <div className="focus" />}
			</div>
		</div>))
		}
	</div>)
}

export default SingleAutoFocusIpt