import { useState, useMemo, useRef } from 'react'

function usePdfPage(operationHeight) {
	const [totalPage, setTotalPage] = useState(0); // 总条数 通过Document获取
	const [scrollTop, setScrolltop] = useState(0) // 页面滚动距离
	const [pdfLoaded, setPdfLoaded] = useState(false) // pdf是否加载完成

	const pdfPageRef = useRef({}) // 用户获取pdf组件高度 计算页码

	// 监听页面滚动
	window.onscroll = () => {
		const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
		setScrolltop(scrollTop)
	}

	const currentPage = useMemo(() => { //通过滚动计算页码
		if (!pdfLoaded) return 0
		if (scrollTop === 0) return 1
		const singlePageHeight = pdfPageRef.current.offsetHeight / totalPage // 组件总高度除以页码 即单页的高度
		return Math.round((scrollTop + window.innerHeight - operationHeight) / singlePageHeight)
	}, [scrollTop, totalPage, pdfLoaded, operationHeight])

	const handleLoaded = () => setPdfLoaded(true) // 设置加载完成

	const handleScrollTo = scrollHeight => { // 控制页面回到底部或顶部
		window.scrollTo({
			top: scrollHeight,
			behavior: 'smooth'
		})
	}

	const handleScrollIntoView = selector => { // 控制页面回到底部或顶部
		document
			.querySelector(selector)
			.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			})
	}

	const handlePageChange = (type, pageHeight) => { // 控制上下翻页
		const topEnum = {
			'up': (currentPage - 2) * pageHeight,
			'down': currentPage * pageHeight,
		}
		window.scrollTo({
			top: topEnum[type],
			behavior: 'smooth'
		})
	}

	return {
		pdfData: {
			pdfPageRef, // pdf组件盒子实例  用于获取到组件总高度 
			pdfLoaded, // pdf加载状态
			currentPage, // 当前页
			totalPage, // 全部页
		},
		pdfOperations: {
			setTotalPage, // 设置全部全部页
			handleLoaded, // 设置pdf加载完成
			handleScrollTo, // 滚动指定高度
			handleScrollIntoView, // 指定元素滚动到视窗
			handlePageChange, // 上下翻页
		},
	}
}

export { usePdfPage }