import "./RealName.less"
import verifyOn from "../../assets/images/verifyOn.png"
import fail from "../../assets/images/fail.png"
import verifyIng from "../../assets/images/verifyIng.png"
import backIcon from "../../assets/images/backIcon.png"
import { useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { getUserAuth, cancelVerifyApi, faceResult } from "../../apis/index"
import classNames from "classnames"
import { Toast } from "antd-mobile"

function RealName() {
  const pathParams = useLocation()
  const responseCode = useRef()
  const notifyUrl = useRef()
  const faceType = useRef()

  const setPath = (path) => {
    let str = path.substring(1, path.length).split('&')
    str = str.map(s => {
      return s.split("=")
    }).flat()
    responseCode.current = str[1]
    notifyUrl.current = str[3]
    faceType.current = str[5]
    if (str[5] == 2) {
      // 获取结果
      verifyResult(str[1])
    } else {
      faceResultAsync(str[1])
    }
  }

  const [data, setData] = useState()

  useEffect(() => {
    setPath(pathParams.search)
  }, [])

  // 根据流水号获取认证结果
  const verifyResult = async (responseCode) => {
    const { data } = await getUserAuth(responseCode)
    setData(data)
  }

  // 人脸识别结果
  const faceResultAsync = async (responseCode) => {
    const { data } = await faceResult(responseCode)
    setData({
      ...data,
      userAuthStatus: data.success ? 2 : 4
    })
  }

  // 遮层罩
  const [mask, setMask] = useState(false)

  // 重新认证  刷新状态  返回
  const firstBtn = (status) => {
    if (status === 4) {
      window.wx.miniProgram.navigateBack({})
    } else if (status === 3) {
      verifyResult(responseCode.current)
    } else if (status === 2) {
      setMask(true)
    }
  }

  // 其他认证方式  取消认证
  const otherChoose = async (status) => {
    if (status === 3) {
      const res = await cancelVerifyApi(responseCode.current)
      if (res.code == '000') {
        Toast.show(`取消成功`)
        setTimeout(() => {
          window.wx.miniProgram.redirectTo({
            url: `/pages/selectVerifyMode/selectVerifyMode?responseCode=${responseCode.current}&notifyUrl=${notifyUrl.current}`
          })
        }, 2000);
      }
    } else {
      window.wx.miniProgram.redirectTo({
        url: `/pages/selectVerifyMode/selectVerifyMode?responseCode=${responseCode.current}&notifyUrl=${notifyUrl.current}`
      })
    }
  }


  return (
    <>
      {
        data?.userAuthStatus ? <div className='result-container'>
          <div className='content'>
            <img src={data?.userAuthStatus === 2 ? verifyOn : data?.userAuthStatus === 3 ? verifyIng : data?.userAuthStatus === 4 ? fail : ''} alt="" />
            <div className={classNames('result', data?.userAuthStatus === 2 ? 'success' : data?.userAuthStatus === 3 ? 'ing' : 'err')}>{data?.userAuthStatus === 2 ? '认证成功' : data?.userAuthStatus === 3 ? '认证审核中' : '认证失败'}</div>
            <div className={classNames('mes', data?.userAuthStatus === 4 ? 'errMes' : '')}>
              {data?.userAuthStatus === 2 ? '您已通过个人实名认证' : data?.userAuthStatus === 3 ? '您的认证需人工审核，约需1～2个工作日' : data?.message}
            </div>
          </div>
          <div className="back-btn" onClick={() => {
            firstBtn(data?.userAuthStatus)
          }}>
            {data?.userAuthStatus === 4 ? '重新认证' : data?.userAuthStatus === 3 ? '刷新认证状态' : '返回'}
          </div>
          {
            data?.userAuthStatus !== 2 ? <div className="second-box" onClick={() => {
              otherChoose(data?.userAuthStatus)
            }}>
              {data?.userAuthStatus === 4 ? '其它认证方式' : '取消认证'}
            </div> : null
          }
          <div className="mask" onClick={() => { setMask(false) }} style={{ display: mask ? 'block' : 'none' }}>
            <img src={backIcon} alt="" />
          </div>
        </div> : null
      }
    </>
  )
}

export default RealName