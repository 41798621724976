import axios from 'axios'
export const base64Upload = (base64) => {
    base64 = base64.split(',')[1]
    return new Promise(async resolve => {
        const { data } = await axios.get('https://www.uoko.com/api/qiniu-token?PrefixType=9')
        resolve(data)
    }).then(async ({ Token, Domain }) => {
        const { data: { key } } = await axios.post('https://up.qiniu.com/putb64/-1', base64, {
            headers: {
                "Content-Type": "application/octet-stream",
                "Authorization": 'UpToken ' + Token
            }
        })
        const url = Domain + key
        return url
    })
}