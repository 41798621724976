import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import wx from "weixin-js-sdk";
import "lib-flexible";
import smoothscroll from "smoothscroll-polyfill";
// import Vconsole from 'vconsole'

// new Vconsole();
smoothscroll.polyfill();

window.wx = wx;

ReactDOM.render(<App />, document.getElementById("root"));
