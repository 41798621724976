import { useEffect, useState } from "react";
function useFAQList(tab) {
  const [FAQList, setFAQList] = useState([]);

  useEffect(() => {
    const FAQData = {
      1: [
        {
          title: "微信小程序支持哪些注册方式？",
          content: `<p>
                    目前，微信小程序可支持通过微信账号一键登录，或通过手机号获取验证码快捷登录。
                    </p>
                    <p>
                    暂不支持电子邮箱类账号注册，以及输入登录密码登录，后续将逐步开放此功能。
                    </p>
                    `,
        },
        {
          title: "为什么签署前要先完成实名认证？",
          content: `<p>
                    有效合法的电子签名，需是经本人同意且自愿签署的。在电子签名上，为了确保签约行为的真实性、合法性、安全性，需签署人先完成本人的实名认证，才可进行签署。
                    </p>
                    <p>
                    通过实名认证，能够确保电子合同的签约行为是对应主体的真实行为和真实意愿表达，可以有效避免使用假身份或冒用他人身份进行电子合同签署情况，确保电子合同证据链的完整。
                    </p>
                    <p>
                    同时，根据《电子签名法》及《合同法》规定，有效的电子合同必须满足以下条件：
                    <br>
                    （一）数据电文原件，能够可靠地保持内容完整、防篡改，满足法律规定的原件形式及文件保存要求；
                    <br>
                    （二）电子签名，能够标识签署人、签署时间，防篡改，满足法律规定的有效电子签名要求；
                    <br>
                    （三）身份经过第三方有效认证，满足法律规定的认证要求。
                    </p>
                    <p>
                    因此，签署合同前，第三方签署平台必须经过严格实名认证技术，来确认签署双方的真实身份。才能为使用者提供安全有效的电子签名，保证签署双方的合法权益。
                    </p>
                    `,
        },
        {
          title: "我可以通过哪些方式完成实名认证？",
          content: `<p>
                    目前签约宝支持：本人手机号三要素、本人银行卡四要素及人工审核三种实名认证方式。
                    </p>
                    <p>
                    推荐使用手机号三要素认证，仅需姓名、身份证号码及手机号三要素匹配为本人信息，即可通过认证。
                    </p>
                    `,
        },
        {
          title: "非中国大陆居民可以使用签约宝吗？",
          content: `<p>
          可以。非中国大陆居民在实名认证环节，部分人员需通过人工审核。人工审核支持护照、港澳居民居住证及台湾居民居住证。
                    </p>
                    `,
        },
        {
          title: "如何进行企业身份认证？",
          content: `<p>
                    目前，企业认证还在内测中，暂未对外开放企业身份认证服务。如需认证为企业身份，可联系签约宝客服完成企业用户升级。
                    </p>
                    `,
        },
      ],
      2: [
        {
          title: "什么是签署密码？",
          content: `<p>
                    签署密码是用于在合同签署过程中，以证明为本人签署意愿的一种验证方式。设置了签署密码后，则无需获取验证码签署，在网络不佳或短信通道拥堵收不到验证码时，可以辅助签署验证。
                    </p>
                    `,
        },
        {
          title: "发起合同后，可以再撤销合同吗？",
          content: `<p>
                    可以。在所有签署方都没有签署完成前，可以申请撤销合同。若双方均已签署完毕，合同状态为“已完成”时，则不能撤回合同，只能申请作废合同，作废合同需双方同意。
                    </p>
                    `,
        },
        {
          title: "签署完成的合同如何保存和下载？",
          content: `<p>
                    签署完成的合同保存在云端服务器，同时通过区块链技术进行备份，合同永久保存。如需下载合同，你可随时在签约宝小程序，打开对应的合同详情页，找到下载入口，即可下载。
                    </p>
                    `,
        }
      ],
      3: [
        {
          title: "电子合同是否具有法律效力？",
          content: `<p>
            《中华人民共和国合同法》第十条和第十一条，以非常明确的形式确定了电子数据和纸面合同一样，是书面形式的一种，具备相同的法律效力：
            <br>
            第十条规定：当事人订立合同，有书面形式、口头形式和其他形式；
            <br>
            第十一条规定：书面形式是指合同书、信件和数据电文（包括电报、电传、传真、电子数据交换和电子邮件）等可以有形地表现所载内容的形式；
            <br>
            同时，《中华人民共和国电子签名法》第十三条和第十四条，以非常明确的形式确定了什么是电子签名，以及电子签名和传统手写签名和盖章具有相同的法律效力。
            <br>
            第十三条规定：“电子签名同时符合下列条件的，视为可靠的电子签名：1）电子签名制作数据用于电子签名时，属于电子签名人专有；2）签署时电子签名制作数据仅由电子签名人控制；3）签署后对电子签名的任何改动能够被发现；4）签署后对数据电文内容和形式的任何改动能够被发现。当事人也可以选择使用符合其约定的可靠的条件的电子签名。
            第十四条规定：可靠的电子签名与手写签名或者盖章具有同等的法律效力。这条法律赋予了电子签名和传统手写签名和盖章相同的法律效力。
            </p>
            `,
        },
        {
          title: "若发生合同纠纷，签约宝能提供什么服务？",
          content: `<p>
            联合公证处，司法鉴定中心，律所，仲裁委员会，为用户提供更全面的公证和司法服务。从发起合同到签署完成，已经数据存证，提供详细的签署日志、签署方信息、签署时间以及查验签署合同是否被窜改等服务。
            </p>
            `,
        },
        {
          title: "签约宝电子合同的主要运用场景有哪些？",
          content: `<p>
            签约宝目前主要面向房产行业，包括：房屋租赁、房屋买卖，兼顾其它行业需求。提供租赁合同模版、物业交割电子清单、电子收据、定金合同等。
            </p>
            `,
        },
        {
          title: "电子合同的优势有哪些？",
          content: `<p>
            电子合同的优势主要体现在三个方面：省时、省钱、安全放心。免除双方需要约定时间见面，奔波到现场签署，可降低时间成本、节省纸张，同时合同可以永久保存。
            </p>
            `,
        },
        {
          title: "哪些场景不适合与电子合同？",
          content: `<p>
            《电子签名法》明确规定了电子合同不适用于以下文书：
            <br>
            (一)涉及婚姻、收养、继承等人身关系的;
            <br>
            (二)涉及土地、房屋等不动产权益转让的;
            <br>
            (三)涉及停止供水、供热、供气、供电等公用事业服务的;
            <br>
            (四)法律、行政法规规定的不适用电子文书的其他情形。
            </p>
            `,
        },
        {
          title: "电子合同能作为证据而被法院采纳吗？",
          content: `<p>
            根据《民事诉讼法》第63条的规定，证据的种类包括电子数据，同时《电子签名法》也规定，数据电文不得仅因为其是以电子、光学、磁或者类似手段生成、发送、接收或者储存的而被拒绝作为证据使用。
            <br>
            因此，符合法律规定条件的电子合同是可以作为电子证据被法院采纳的。
            </p>
            `,
        },
      ],
    };

    setFAQList(FAQData[tab] || []);
  }, [tab]);

  return { FAQList };
}

export { useFAQList };
