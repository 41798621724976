import { 
  forwardRef, 
  useImperativeHandle,
  useState, 
  useEffect, 
} from "react";
import { Toast } from 'antd-mobile'
import { useBoard } from "../../hooks/useBoard";
import boardTitleIcon from "@/assets/images/board_title.png";
import "./index.less";

const Board = forwardRef((
  {
    data: {
      signingList,
    },
    operations: {
      setSigningList,
      handleScrollIntoView,
    },
  }, 
  ref,
) => {
  const [visible, setVisible] = useState(false)
  const [showMyToast, setShowMyToast] = useState(false)
  const [isQuickSign, setIsQuickSign] = useState(false)

  const {
    canvasContainerRef, // canvas父元素实例
    canvasWidth, // 通过canvasContainerRef获取的宽
    canvasHeight, // 通过canvasContainerRef获取的高
    rotateStatus, // 控制签字板旋转效果
    penColor, // 画笔颜色
    setPenColor, // 设置画笔颜色
    colorEnum, // 颜色枚举
    drawStart, // 画笔触及
    drawMove, // 画笔移动
    drawEnd, // 画笔提起
    clearDraw, // 清除画画
    sureDraw, // 确认画
    isDrew, // 是否动过笔
  } = useBoard(visible);

  useImperativeHandle(
    ref,
    () => ({
      open: ({isQuickSign} = {}) => {
        setVisible(true)
        setIsQuickSign(!!isQuickSign)
      },
      close: _ => handleClose()
    }),
  )

  const handleClose = _ => {
    setIsQuickSign(false)
    setVisible(false)
  }

  // 完成签名
  const sure = () => {
    if (!isDrew) return setShowMyToast(true)
    const isHandwriteWidget = it => [2].includes(it.widgetType) // widgetType -> 2-经办人签字
    const base64 = sureDraw();
    if (isQuickSign) {
      setSigningList(prev => prev.map(it => (
        isHandwriteWidget(it)
          ? {
            ...it, 
            signValue: it.signValue || base64, 
            isSignature: true,
          }
          : it
      )))
      Toast.success('一键签署完成', 1.2)
      handleClose()
    } else {
      const target = signingList // 获取签字类型下第一个待签署控件
        ?.filter(it => !it.signValue && isHandwriteWidget(it))
        ?.[0]

      const nextSigningList = signingList.map(it =>
        it.id === target?.id
          ? {...it, signValue: base64, isSignature: true}
          : it
      )
      const isComplete = nextSigningList
        .filter(it => isHandwriteWidget(it))
        .every(it => it.signValue)
      setSigningList(nextSigningList)
      handleClose()
      isComplete
        ? Toast.info('已完成签名', 1.2)
        : handleScrollIntoView(`#signing-widget-${target.id}`)
    }
  };

  // 简单应用于签字板提示 
  const MyToast = ({
    title,
  }) => {
    const [showAnimate, setShowAnimate] = useState(false)
    useEffect(() => {
      setShowAnimate(true)
      setTimeout(() => setShowMyToast(false), 1500)
    }, [])
    return <div className="my-toast-mask">
      <div className={`my-toast ${showAnimate && "translate"}`}>{title}</div>
    </div>
  }

  return visible
    ? (
      <div
        id="board"
        className="board-comp-signboard"
        style={{
          width: window.innerHeight,
          height: window.innerWidth,
          transform: `translate(-50%, -50%) ${rotateStatus ? 'rotate(90deg)' : ''}`,
        }}
      >
        {showMyToast && <MyToast title="请在指定位置写下签名" />}
        <img className="title-i" src={boardTitleIcon} alt="" />
        <div className="sign-area" ref={canvasContainerRef}>
          <canvas
            id="signDraw"
            width={canvasWidth}
            height={canvasHeight}
            onTouchStart={drawStart}
            onTouchMove={drawMove}
            onTouchEnd={drawEnd}
          />
        </div>
        <div className="operation-wrap">
          <div className="color-select">
            {colorEnum.map(item => (
              <div
                key={item.color}
                className="color-box"
                onClick={() => setPenColor(item.color)}
              >
                {item.color === penColor ? item.checkedEl : item.checkEl}
              </div>
            ))}
          </div>
          <div className="btns">
            <span className="text-operation" onClick={handleClose}>
              返回
            </span>
            <span className="text-operation" onClick={clearDraw}>
              清除
            </span>
            <button className="sure-btn" onClick={sure}>
              确定
            </button>
          </div>
        </div>
      </div>
    )
    : null
});

export default Board;
