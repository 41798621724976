export const toggleSideBarTransparent = ((duration = 1500) => {
  let timer
  const add = () => document.querySelector('#sign-side-operation')
    .classList
    .add('toggle-transparent')
  const remove = () => document.querySelector('#sign-side-operation')
    .classList
    .remove('toggle-transparent')

  return () => {
    if (timer) {
      clearTimeout(timer)
      timer = null
      remove()
    }
    timer = setTimeout(() => {
      remove()
    }, duration);
    add()
  }
})()

export const isIos = () => {
	const u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}