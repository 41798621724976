import SignBoard from "./views/SignBoard";
import Seal from "./views/Seal";
import FAQ from "./views/FAQ";
import About from "./views/About";
import ContactUs from "./views/ContactUs";
import JumpWeApp from './views/JumpWeApp'
import RealName from "./views/RealName/RealName";
import Handwrite from "./views/Handwrite";
import PreviousTemplate from "./views/previousTemplate";
import { Switch, Route, HashRouter } from "react-router-dom";

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route
          exact
          path="/signboard/:info"
          render={(props) => <SignBoard {...props} />}
        />
        <Route
          exact
          path="/seal/:info"
          render={(props) => <Seal {...props} />}
        />
        <Route 
          exact 
          path="/handwrite/:info" 
          render={(props) => <Handwrite {...props} />}
        />
        <Route 
          exact 
          path="/previous/:info" 
          render={(props) => <PreviousTemplate {...props} />}
        />
        <Route exact path="/FAQ" render={(props) => <FAQ {...props} />} />
        <Route exact path="/about" render={(props) => <About {...props} />} />
        <Route exact path="/contactus" render={(props) => <ContactUs {...props} />} />
        <Route exact path="/realName" render={(props) => <RealName {...props} />}></Route>
        <Route exact path="/jumpweapp/:t" render={(props) => <JumpWeApp {...props} />} />
      </Switch>
    </HashRouter>
  );
}

export default App;
