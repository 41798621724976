import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Toast } from "antd-mobile";
import { toggleSideBarTransparent } from '../../utils'
import ExpandIcon from '@/assets/images/icon_expand.png'
import CollapseIcon from '@/assets/images/icon_collapse.png'
import LocateIcon from '@/assets/images/icon_sign_locate.png'
import QuickIcon from '@/assets/images/icon_sign_quick.png'
import PersonSealIcon from '@/assets/images/icon_person_seal.png'
import EnterpriseSealIcon from '@/assets/images/icon_enterprise_seal.png'
import HandwriteIcon from '@/assets/images/icon_handwrite.png'
import DateIcon from '@/assets/images/icon_sign_date.png'
import "./index.less";

// 未签署完成则遍历未签署控件 否则遍历已签署控件
const getTargetList = signingList => {
  const unsignedList = signingList?.filter(it => !it.signValue)
  const isComplete = !(!!unsignedList.length)
  return isComplete
    ? signingList 
    : unsignedList
}

// 根据当前list长度决定下一次未签署index
const getNextUnsignedIdx = (targetList, unsignedIdx) => {
  return unsignedIdx + 1 > targetList.length - 1
    ? 0
    : unsignedIdx + 1
}

const SideBar = ({
  data: {
    sealSignRef,
    boardRef,
    dateSignRef,
    signingList, // 控件列表
    signInfo, // 签署节点信息
  },
  operations: {
    handleScrollIntoView,
    setShowQuickSign,
  },
}) => {
  const [expand, setExpand] = useState(true)
  const [transparent, setTransparent] = useState(false)
  const [showTips, setShowTips] = useState(true)
  const [unsignedIdx, setUnsignedIdx] = useState(0) // 当前未签署控件index

  const operationData = useMemo(() => ({
    signingList,
    type: {
      1: 'person',
      2: 'enterprise',
    }[signInfo?.signatoryType]
  }), [signInfo, signingList])

  // 判断侧边栏是否遮挡签署控件
  const ifCovered = useCallback(() => {
    const sideBarRect = document
      .querySelector('#sign-side-operation')
      .getBoundingClientRect()
  
    const positionList = signingList.map(it => document
      .querySelector(`#signing-widget-${it.id}`)
      .getBoundingClientRect()
    )
  
    const hasCoveredWidget = positionList.some(it => {
      return it.top + it.height > sideBarRect.top 
        && it.top < sideBarRect.top + sideBarRect.height 
        && it.left + it.width > sideBarRect.left
    })
    setTransparent(hasCoveredWidget)
  }, [signingList])

  // 设置签署定位功能当前index
  useEffect(() => {
    const targetList = getTargetList(signingList) 
    const nextUnsignedIdx = getNextUnsignedIdx(targetList, unsignedIdx)

    setUnsignedIdx(nextUnsignedIdx)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signingList])

  // 提示5秒后隐藏
  useEffect(() => {
    setTimeout(() => {
      setShowTips(false)
    }, 5000);
  }, [])

  // 判断侧边栏是否遮挡签署控件-页面滚动
  useEffect(() => {
    window.addEventListener('scroll', ifCovered)
    return () => window.removeEventListener('scroll', ifCovered)
  }, [ifCovered])

  // 判断侧边栏是否遮挡签署控件-展开收起
  useEffect(() => {
    ifCovered()
  }, [expand, ifCovered])

  const operationList = ({
    signingList,
    type,
  }) => [
    {
      icon: LocateIcon,
      type: 'common',
      text: _ => `${signingList?.filter(it => it.signValue)?.length} / ${signingList?.length}`,
      handler: _ => {
        const targetList = getTargetList(signingList)
        if (!targetList.length) return
        const { id: widgetId } = targetList[unsignedIdx]
        if (widgetId) {
          toggleSideBarTransparent()
          handleScrollIntoView(`#signing-widget-${widgetId}`)
          setUnsignedIdx(getNextUnsignedIdx(targetList, unsignedIdx))
        }
      },
    },
    {
      icon: QuickIcon,
      type: 'common',
      text: _ => `一键签署`,
      extraText: _ => `(共${signingList?.length ?? '-'}处)`,
      handler: _ => {
        if (signInfo?.signatoryType === 1) { // 个人 判断是否存在签名控件
          const hasSealSign = signingList.some(it => it.widgetType === 2)
          hasSealSign
            ? setShowQuickSign(prev => !prev) // 打开一键签署弹窗
            : Toast.info('当前文件不存在待签署签名', 1.5)
        } else if (signInfo?.signatoryType === 2) {
          sealSignRef.current.open({isQuickSign: true}) // 企业 直接打开企业印章弹窗
        }
      },
    },
    {
      icon: HandwriteIcon,
      type: 'person',
      text: _ => `手写签名`,
      handler: _ => {
        const hasHandwriteSign = signingList.some(it => it.widgetType === 2)
        if (hasHandwriteSign) {
          const isComplete = signingList
            .filter(it => it.widgetType === 2)
            .every(it => it.signValue)
          isComplete
            ? Toast.info('您已完成签名', 1.2)
            : boardRef.current.open()
        } else {
          Toast.info('当前文件不存在待签署签名', 1.5)
        }
      },
    },
    {
      icon: PersonSealIcon,
      type: 'person',
      text: _ => `个人印章`,
      handler: _ => {
        const isSealWidget = it => it.widgetType === 2
        const hasSealSign = signingList.some(it => isSealWidget(it))
        if (hasSealSign) {
          const isComplete = signingList
            .filter(it => isSealWidget(it))
            .every(it => it.signValue)
          isComplete
            ? Toast.info('您已完成签名', 1.2)
            : sealSignRef.current.open()
        } else {
          Toast.info('当前文件不存在待签署签名', 1.5)
        }
      },
    },
    {
      icon: EnterpriseSealIcon,
      type: 'enterprise',
      text: _ => `企业印章`,
      handler: _ => {
        const isEnterpriseWidget = it => [1, 4].includes(it.widgetType)
        const hasEnterpriseSealSign = signingList.some(it => isEnterpriseWidget(it))
        if (hasEnterpriseSealSign) {
          const isComplete = signingList
            .filter(it => isEnterpriseWidget(it))
            .every(it => it.signValue)
          isComplete
            ? Toast.info('您已完成签署', 1.2)
            : sealSignRef.current.open()
        } else {
          Toast.info('当前文件不存在待签署印章', 1.5)
        }
      },
    },
    {
      icon: DateIcon,
      type: 'person',
      text: _ => `签署日期`,
      handler: _ => {
        const isDateWidget = it => it.widgetType === 3
        const hasDateSign = signingList.some(it => isDateWidget(it))
        if (hasDateSign) {
          const isComplete = signingList
            .filter(it => isDateWidget(it))
            .every(it => it.signValue)
          isComplete
            ? Toast.info('您已完成日期签署', 1.2)
            : dateSignRef.current.open()
        } else {
          Toast.info('当前文件不存在待签署日期', 1.5)
        }
      },
    },
  ]
    .filter(it => ['common', type].includes(it.type))

  const handleToggleExpand = () => {
    setShowTips(false)
    setExpand(prev => !prev)
  }

  return (
    <aside id='sign-side-operation' className={`${transparent ? 'transparent' : ''} sign-side-operation flex-c`}>
      <div className="title">签署操作区</div>
      <section className={`main ${expand ? 'expand' : 'collapse'}`}>
        {
          showTips && 
            <div className="tips_wrapper">
              <span className="tips">点击可快速定位需要您签署的区域</span>
            </div>
        }
        {
          expand && 
            operationList(operationData).map((it, idx) => (
              <div 
                key={idx} 
                style={it.style || {}}
                className="operation_btn flex-c"
                onClick={() => it.handler(it)}
              >
                <img src={it.icon} className="icon-24" alt="" />
                <span className="mt-4">{it.text()}</span>
                {
                  it.extraText && 
                    (<span style={{color: 'rgba(54, 60, 90, .3)'}} className="mt-4">
                      {it.extraText()}
                    </span>)
                }
              </div>
            ))
        }
      </section>
      <div onClick={handleToggleExpand} style={{justifyContent: 'center'}} className="footer flex-center">
        <span>{expand ? '收起' : '展开'}</span>
        <img src={expand ? CollapseIcon : ExpandIcon} className="icon-16 ml-4" alt="" />
      </div>
    </aside>
  );
};

export default SideBar;
