import { 
  forwardRef, 
  useImperativeHandle, 
  useState, 
} from "react";
import { useHistory } from 'react-router-dom'
import { Modal, Toast } from "antd-mobile";
import CloseIcon from '@/assets/images/icon_close.png'
import PersonSealIcon from '@/assets/images/icon_person_seal.png'
import EnterpriseSealIcon from '@/assets/images/icon_enterprise_seal.png'
import "./index.less";

const alert = Modal.alert

// 企业无印章且无生成权限时 弹窗错误提示
const showError = sealErrorMessage => {
  alert(
    <span className="modal-alert-title">提示</span>, 
    sealErrorMessage,
    [
      { 
        text: '知道了', 
        style: {
          letterSpacing: 1, 
          fontSize: 17, 
          fontWeight: 'bold', 
          color: '#576B95'
        },
      },
    ]
  )
}

const SealSign = forwardRef((
  {
    data: {
      signingList,
      signInfo,
      sealList,
      sealErrorMessage,
    },
    operations: {
      setSigningList,
      setShowCreateSeal,
      handleScrollIntoView,
    },
  }, 
  ref,
) => {
  const history = useHistory()

  const [visible, setVisible] = useState(false)
  const [isQuickSign, setIsQuickSign] = useState(false)

  const isEnterprise = signInfo.signatoryType === 2
  const typeText = isEnterprise ? '企业' : '个人' 

  useImperativeHandle(
    ref,
    () => ({
      open: async ({isQuickSign} = {}) => {
        if (isQuickSign) {
          setIsQuickSign(!!isQuickSign)
          setVisible(true)
        } else {
          const isSealWidget = it => { // widgetType -> 1-企业盖章 2-经办人签字 4-骑缝章
            const targetTypes = isEnterprise
              ? [1, 4]
              : [2]
            return targetTypes.includes(it.widgetType)
          }
          const target = signingList 
            ?.filter(it => !it.signValue && isSealWidget(it))
            ?.[0]
          
          if (target) {
            handleScrollIntoView(`#signing-widget-${target.id}`)

            setTimeout(() => {
              setIsQuickSign(!!isQuickSign)
              setVisible(true)
            }, 650);
          }
        }
      },
      close: _ => handleClose(),
    }),
  )

  const handleClose = _ => {
    setIsQuickSign(false)
    setVisible(false)
  }

  const isSealWidget = it => { // widgetType -> 1-企业盖章 2-经办人签字 4-骑缝章
    const targetTypes = isEnterprise
      ? [1, 4]
      : [2]
    return targetTypes.includes(it.widgetType)
  } 

  const handleSign = _ => {
    if (isQuickSign) {
      setSigningList(prev => prev.map(it => (
        isSealWidget(it)
          ? {
            ...it, 
            signValue: sealList?.[0].stampFile,
            stampId: sealList[0]?.id,
          }
          : it
      )))
      Toast.success('一键签署完成', 1.2)
      handleClose()
    } else {
      const target = signingList // 获取签字类型下第一个待签署控件
        ?.filter(it => !it.signValue && isSealWidget(it)) 
        ?.[0]

      const nextSigningList = signingList
        .map(it =>
          it.id === target?.id
            ? {
              ...it, 
              signValue: sealList?.[0].stampFile,
              stampId: sealList[0]?.id,
            }
            : it
        )
      setSigningList(nextSigningList)
      handleClose()

      const isComplete = nextSigningList
        .filter(it => isSealWidget(it))
        .every(it => it.signValue)
      const nextTarget = nextSigningList
        .filter(it => !it.signValue && isSealWidget(it))?.[0]
      isComplete
        ? Toast.info('已完成签名', 1.2)
        : handleScrollIntoView(`#signing-widget-${nextTarget.id}`)
    }
  }

  const handleCreateSeal = _ => {
    if (sealErrorMessage) { // 若无印章生成权限时
      return showError(sealErrorMessage)
    }

    // TODO?: 跳转生成企业印章
    if (isEnterprise) { // 若为企业印章 跳转回小程序进行生成
      return window.wx.miniProgram.redirectTo({
        url: `/pages/selectVerifyMode/selectVerifyMode?responseCode=${''}&notifyUrl=${''}`
      })
    }

    setVisible(false)
    setShowCreateSeal(prev => !prev)
  }

  return (
    <Modal
      visible={visible}
      maskClosable={false}
      wrapClassName="seal-sign-modal-wrapper"
      transparent
    >
      <section className="seal-sign-modal flex-c">
        <img src={CloseIcon} onClick={() => setVisible(false)} className="icon-24 icon-close" alt="" />

        <div className="title flex-center">
          <img src={isEnterprise ? EnterpriseSealIcon : PersonSealIcon} className="icon-24" alt="" />
          <span className="ml-4">使用{typeText}印章签署</span>
        </div>

        <div className="sign-count mt-4">
          共{signingList?.filter(it => isSealWidget(it))?.length ?? '-'}处，
          已签章{signingList?.filter(it => it.signValue && isSealWidget(it))?.length}处
        </div>

        <div className="sign-item mt-12">
          {
            sealList?.[0]
              ? (
                <div 
                  style={{alignItems: 'center'}} 
                  className="flex-c"
                  onClick={handleSign}
                >
                  {
                    isEnterprise
                      ? <img src={sealList[0]?.stampFile} style={{width: '96px', height: '96px'}} alt="" />
                      : <img src={sealList[0]?.stampFile} style={{width: '102px', height: '48px'}} alt="" />
                  }
                  <span style={{fontSize: 14, fontWeight: 'bold', color: '#363C5A'}} className="mt-12">
                    点击印章完成签名
                  </span>
                </div>
              )
              : <button onClick={handleCreateSeal} className="btn-create-seal">
                未生成{typeText}印章，点击生成
              </button>
          }
        </div>
      </section>
    </Modal>
  );
})

export default SealSign;
