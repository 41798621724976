import {
  useState,
  useLayoutEffect,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
import { Toast } from 'antd-mobile'
import { useBoard } from "./hooks/useBoard";
import { addSeal } from '@/apis'
import { base64Upload } from '@/utils/base64Upload'
import boardTitleIcon from "@/assets/images/board_title.png";
import "./index.less";

const Board = () => {
  const { info } = useParams();
  const {
    token,
  } = JSON.parse(info);
  token && (sessionStorage.token = token)

  const [showMyToast, setShowMyToast] = useState(false)

  const {
    canvasContainerRef, // canvas父元素实例
    canvasWidth, // 通过canvasContainerRef获取的宽
    canvasHeight, // 通过canvasContainerRef获取的高
    rotateStatus, // 控制签字板旋转效果
    penColor, // 画笔颜色
    setPenColor, // 设置画笔颜色
    colorEnum, // 颜色枚举
    drawStart, // 画笔触及
    drawMove, // 画笔移动
    drawEnd, // 画笔提起
    clearDraw, // 清除画画
    sureDraw, // 确认画
    isDrew, // 是否动过笔
  } = useBoard();

  useLayoutEffect(() => {
    setTimeout(() => {
      document
        .querySelector('.am-toast-notice')
        ?.classList
        .add('page_handwrite')
    }, 0);
  }, [])

  // 返回首页
  const handleBack = _ => {
    window.wx.miniProgram.reLaunch({
      url: '/pages/home/index',
    })
  }

  // 完成并保存电子签名
  const handleSave = async () => {
    if (!isDrew) return setShowMyToast(true)
    const base64 = sureDraw();
    const url = await base64Upload(base64)
    await addSeal(url, true)
    Toast.success('保存成功', 1.5)
    setTimeout(() => {
      handleBack()
    }, 1200);
  };

  // 简单应用于签字板提示 
  const MyToast = ({
    title,
  }) => {
    const [showAnimate, setShowAnimate] = useState(false)
    useEffect(() => {
      setShowAnimate(true)
      setTimeout(() => setShowMyToast(false), 1500)
    }, [])
    return <div className="my-toast-mask">
      <div className={`my-toast ${showAnimate && "translate"}`}>{title}</div>
    </div>
  }

  return (
    <div
      id="board"
      className="board-comp-handwrite"
      style={{
        width: window.innerHeight,
        height: window.innerWidth,
        transform: `translate(-50%, -50%) ${rotateStatus ? 'rotate(90deg)' : ''}`,
      }}
    >
      {showMyToast && <MyToast title="请在指定位置写下签名" />}
      <img className="title-i" src={boardTitleIcon} alt="" />
      <div className="sign-area" ref={canvasContainerRef}>
        <canvas
          id="signDraw"
          width={canvasWidth}
          height={canvasHeight}
          onTouchStart={drawStart}
          onTouchMove={drawMove}
          onTouchEnd={drawEnd}
        />
      </div>
      <div className="operation-wrap">
        <div className="color-select">
          {colorEnum.map(item => (
            <div
              key={item.color}
              className="color-box"
              onClick={() => setPenColor(item.color)}
            >
              {item.color === penColor ? item.checkedEl : item.checkEl}
            </div>
          ))}
        </div>
        <div className="btns">
          {/* <span className="text-operation" onClick={handleBack}>
              返回
            </span> */}
          <span className="text-operation" onClick={clearDraw}>
            清除
          </span>
          <button className="sure-btn" onClick={handleSave}>
            确定
          </button>
        </div>
      </div>
    </div>
  )
};

export default Board;
