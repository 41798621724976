import React, { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import jumpContentIcon from '@/assets/images/jump_content.png'
import jumpTitleIcon from '@/assets/images/jump_title.png'
import miniProgramIcon from '@/assets/images/miniProgram.png'
import "./index.less"

const JumpWeApp = () => {
  const { t = '' } = useParams()

  const url = `weixin://dl/business/?t=${t}`

  const jump = () => {
    window.location.href = url
  }

  useLayoutEffect(() => {
    jump()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div
    className="jump-weapp-wrapper"
    style={{ minHeight: window.innerHeight }}
  >
    <img className="jump-title-icon" src={jumpTitleIcon} alt="" />
    <img className="jump-content-icon" src={jumpContentIcon} alt="" />
    <button className="btn" onClick={jump}>
      <img src={miniProgramIcon} alt="" />
      <span>
        打开签约宝小程序
      </span>
    </button>
  </div >
}

export default JumpWeApp