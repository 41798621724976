export const formatPhone = function (phoneNum) {
	if (phoneNum === void 0 || !phoneNum) {
		phoneNum = ''
	}
	if (typeof phoneNum !== 'string') {
		phoneNum = phoneNum.toString()
	}
	if (phoneNum) {
		return phoneNum.substring(0, 3) + '****' + phoneNum.substring(7)
	} else {
		return ''
	}
}
export const isBase64 = (str = '') => {
	if (str === '' || str.trim() === '') {
		return false;
	}
	try {
		return btoa(atob(str)) === str;
	} catch (err) {
		return false;
	}
}

export function isWeChat() {
	const ua = window.navigator.userAgent.toLowerCase()
	if (ua.match(/MicroMessenger/i) && ua.match(/MicroMessenger/i)[0] === 'micromessenger') {
		return true
	}
	return false
}