import React from "react";
import { useHistory } from 'react-router-dom'
import { Modal, Toast } from "antd-mobile";
import dayjs from "dayjs";
import CloseIcon from '@/assets/images/icon_close.png'
import QuickIcon from '@/assets/images/icon_sign_quick.png'
import PersonSealIcon from '@/assets/images/icon_person_seal.png'
import HandwriteIcon from '@/assets/images/icon_handwrite.png'
import "./index.less";

const { alert } = Modal

// 个人-一键签署
const PersonSign = ({
  sealList,
  handleSign,
  handleCreateSeal,
}) => (
  <div className="sign-list mt-8">
    {/* 个人印章 */}
    <div className="sign-item flex-c">
      <div className="flex-center">
        <img src={PersonSealIcon} className="icon-24" alt="" />
        <span style={{fontSize: 14, color: '#363C5A'}} className="ml-8">个人印章</span>
      </div>

      <div style={{marginTop: 14}}>
        {
          sealList?.[0]
            ? (
              <div 
                style={{alignItems: 'center'}} 
                className="flex-c"
                onClick={() => handleSign('seal')}
              >
                <img src={sealList[0]?.stampFile} style={{width: '102px', height: '48px'}} alt="" />
                <span style={{fontSize: 14, fontWeight: 'bold', color: '#363C5A'}} className="mt-12">点击印章完成签名</span>
              </div>
            )
            : <button onClick={handleCreateSeal} className="btn-create-seal">未生成，点击生成</button>
        }
      </div>
    </div>
    
    {/* 手写签名 */}
    <div className="sign-item flex-c mt-16">
      <div className="flex-center">
        <img src={HandwriteIcon} className="icon-24" alt="" />
        <span style={{fontSize: 14, color: '#363C5A'}} className="ml-8">手写签名</span>
      </div>

      <div style={{marginTop: 14}}>
        <button onClick={() => handleSign('handwrite')} className="btn-handwrite">点击签名</button>
      </div>
    </div>
  </div>
)

const QuickSign = ({
  data: {
    boardRef,
    signingList,
    sealList,
    userInfo,
  },
  operations: {
    setSigningList,
    setShowQuickSign,
  }
}) => {
  const history = useHistory()

  const handleSign = type => ({
    seal: handleSignSeal,
    handwrite: handleSignHandwrite,
  }[type]())

  // 生成个人印章
  const handleCreateSeal = () => {
    const isValidName = userInfo?.userName?.length > 4 
      || !((/^[\u4E00-\u9FA5]+$/).test(userInfo?.userName))
    if (isValidName) {
      return alert(
        <span className="modal-alert-title">提示</span>, 
        '暂不支持中文名字超过4个字或非中文名字的用户生成印章', 
        [
          { 
            text: '知道了', 
            style: {
              letterSpacing: 1, 
              fontSize: 17, 
              fontWeight: 'bold', 
              color: '#576B95'
            } 
          },
        ]
      )
    }

    if (!sealList.length) return alert(
      <span className="modal-alert-title">提示</span>, 
      '您尚未设置个人电子印章，是否立即生成印章？', 
      [
        { 
          text: '现在不用',
          style: {
            fontSize: 17, 
            fontWeight: 'bold', 
            color: 'rgba(0, 0, 0, .8)'
          },
        },
        { 
          text: '立即生成', 
          style: {
            fontSize: 17, 
            fontWeight: 'bold', 
            color: '#576B95'
          },
          onPress: () => {
            const info = JSON.stringify({
              name: userInfo?.userName,
              id: userInfo?.id,
              dev: 'web'
            })

            history.push('/seal/' + info)
          },
        },
      ]
    )
  }
  // 签署-个人印章
  const handleSignSeal = () => {
    const isSignWidget = it => [2].includes(it.widgetType)
    const isDateWidget = it => [3].includes(it.widgetType)
    setSigningList(prev => prev.map(it => (
      isSignWidget(it) || isDateWidget(it)
        ? {
          ...it, 
          signValue: it.signValue || 
            (isSignWidget(it)
              ? sealList[0]?.stampFile
              : dayjs().format('YYYY-MM-DD')),
          stampId: it.stampId || 
            (isSignWidget(it)
              ? sealList[0]?.id
              : ''),
        }
        : it
      )))
    Toast.success('一键签署完成', 1.2)
    setShowQuickSign(false)
  }
  // 签署-手写签名
  const handleSignHandwrite = () => {
    const isDateWidget = it => [3].includes(it.widgetType)
    setSigningList(prev => prev.map(it => (
      isDateWidget(it)
        ? {
          ...it, 
          signValue: it.signValue || (dayjs().format('YYYY-MM-DD')),
          stampId: it.stampId || '',
        }
        : it
      )))
    boardRef.current.open({isQuickSign: true})
    setShowQuickSign(false)
  }

  return (
    <Modal
      visible={true}
      maskClosable={false}
      wrapClassName="quick-sign-modal-wrapper"
      transparent
    >
      <section className="quick-sign-modal flex-c">
        <img src={CloseIcon} onClick={() => setShowQuickSign(false)} className="icon-24 icon-close" alt="" />

        <div className="title flex-center">
          <img src={QuickIcon} className="icon-24" alt="" />
          <span className="ml-4">一键签署</span>
          <span className="sign-count">
            （共{signingList?.length ?? '-'}处，
            已签章{signingList?.filter(it => it.signValue)?.length ?? '-'}处）
          </span>
        </div>

        <div className="mt-16 tips">选择一键签署的方式：</div>

        <PersonSign 
          sealList={sealList}
          handleSign={handleSign}
          handleCreateSeal={handleCreateSeal}
        />
      </section>
    </Modal>
  );
};

export default QuickSign;
