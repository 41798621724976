import React from "react";
import logoIcon from "../../assets/images/logo.png";
import liIcon from "../../assets/images/li_icon.png";
import "./index.less";

const About = () => {
  const serviceList = [
    "电子合同",
    "电子收据",
    "电子借条",
    "电子签名",
    "电子印章",
    "合同存证",
    "租赁备案",
    "商户收款",
    "……",
  ];
  document.title = "关于签约宝";

  return (
    <div className="about-wrapper">
      <img src={logoIcon} alt="" style={{borderRadius:'16px'}} />
      <h1 className="logo-text">签约宝</h1>
      <p className="text">
        签约宝是服务于个人、企业用户的电子远程签约平台，目前以房屋租赁、买卖交易为主要服务行业，同时兼顾其它行业需要。平台集成了电子合同、电子签名以及电子印章等主要功能，为用户提供电子合同签署、电子收据开具及区块链证据存证等服务。
      </p>
      <p className="text">
        在遵循及符合《中华人民共和国民法典》《中华人民共和国电子签名法》等法律法规前提下，平台确保签订的文件具备充分的法律效力。同时，积极探索与公证机构、仲裁机构、互联网法院、律师事务所等合作，为合同纠纷等提供后续必要的保障服务。
      </p>
      <p className="text">
        签约宝具有签约速度快、使用成本低等特点。平均3分钟即可签署填写签署完毕一份房屋租赁合同。同时，合同无需快递、无需当面签署，减少纸质打印成本及当面签署的交通成本等。
      </p>
      <p className="text">
        签约宝采用权威CA机构颁发的SSL证书加密传输混合加密机制，以及多端云服务部署，确保文件信息安全。
      </p>
      <h1 className="service-title">我们提供以下服务</h1>
      <ul className="service-list">
        {serviceList.map((item, index) => (
          <li key={index}>
            <img src={liIcon} alt="" />
            <span>{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default About;
