import React, { useMemo, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
// import { ENV } from '@/config'
import "./index.less";


pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker_2.6.js`;

const PageView = (props) => {



  const {
    pdfPageRef,
    setTotalPage,
    pdfUrl,
    totalPage,
    handleLoaded,
    signUrl,
    signUrlType,
    voList = [],
    a4Rect,
    sealRect,
    contractType // 4 是授权签署
  } = props;

  // 通过计算后的坐标数组
  const calcList = useMemo(() => {
    if (!voList) {
      return []
    }
    // contractType==4 签署入驻授权书 5 划石社区合同模板
    if (contractType == 4) {
      return voList.map(item => ({
        left: (item.x / a4Rect.w) * window.innerWidth,
        top: '321px'
      }))
    } else if (contractType == 5) {
      return voList.map(item => ({
        left: (item.x / a4Rect.w) * window.innerWidth,
        top: ((a4Rect.h - item.y) + (item.page - 1.05) * a4Rect.h) / a4Rect.w * window.innerWidth
      }))
    } else {
      return voList.map(item => ({
        left: (item.x / a4Rect.w) * window.innerWidth,
        top: ((a4Rect.h - item.y) + (item.page - 0.03) * a4Rect.h) / a4Rect.w * window.innerWidth
      }))
    }
  }, [voList, a4Rect])

  useEffect(() => {
    // 监听到signUrl发生变化后，滚动至签署的第一处
    if (!signUrl) return
    window.scrollTo({ // 使页面滚动至盖章处居中位置
      top: calcList[0]?.top - window.innerHeight / 2,
      behavior: 'smooth'
    })
  }, [signUrl, calcList])


  const loadErr = (e) => console.log(e)


  return (
    <div id="pdfView-previous" ref={pdfPageRef}>

      {signUrl && !!calcList.length && (
        calcList.map((item, index) => <div
          key={index}
          className={`sign-img-bg-previous ${signUrlType === 2 && 'bg'}`}
          style={{ left: item.left, top: item.top }}
        >
          <img
            style={{
              width: window.innerWidth / a4Rect.w * sealRect.w,
              height: window.innerWidth / a4Rect.w * sealRect.h,
            }}
            src={signUrl}
            alt=""
          />
        </div>)
      )}
      <Document
        file={pdfUrl}
        onLoadSuccess={({ numPages }) => setTotalPage(numPages)}
        loading="pdf加载中"
        noData="pdf加载中"
        renderMode="canvas"
        onLoadError={(e) => {
          loadErr(e)
          console.log(e);
        }}
      >
        {Array.from({ length: totalPage }).map((item, index) => {
          return (
            <Page
              loading=""
              key={index}
              width={window.innerWidth}
              pageNumber={index + 1}
              onLoadSuccess={() => index + 1 === totalPage && handleLoaded()}
            />
          );
        })}
      </Document>
    </div >
  );
};

export default PageView;
