import React, { useEffect, useState } from "react";
import PageView from "./components/PageView";
import Operations from "./components/Operations";
import Board from "./components/Board";
import PwdContent from "./components/PwdContent";
import { useSearchContract } from "./hooks/useSearchContract";
import { usePdfPage } from "./hooks/usePdfPage";
import { Toast, Modal, Button } from "antd-mobile";
import { ENV } from "@/config";
import { getByContractId } from '@/apis'
import "./index.less";

const SignBoard = () => {
  const [showBoard, setShowBoard] = useState(false); // 打开或关闭签字板
  const [signUrlType, setSignUrLType] = useState(); // 签章类型 1.电子签章 2.手写签名

  const {
    pdfUrl,
    phone,
    realName,
    voList,
    id,
    contractType, // 判断是授权书，还是租赁合同  1 是合同签署，4 是授权签署  5 划石社区合同模板
    isSetPwd,
    sealList,
    infoLoaded,
    userId,
    sourceType,
    companyJoinVerifyId
  } = useSearchContract();

  // 一个合适的签章大小
  const [sealRect] = useState({
    w: 108,
    h: 55,
  });

  const operationHeight = (83 / 375) * window.innerWidth; // 底部控件的高度

  // 切换验证方式
  const [codeType, setCodeType] = useState(2);
  const [pwdPop, setPwdPop] = useState(false); // 设置签署密码popup状态

  // a4大小 通过该大小根据不同手机按比例定位
  const [a4Rect, setA4Rect] = useState({});

  useEffect(() => {
    const rect =
      ENV === "test1" || ENV === "test2" || sourceType !== 0
        ? // || ENV === 'development'
        {
          w: 595,
          h: 841,
        }
        : {
          w: 612,
          h: 792,
        };

    setA4Rect(rect);
  }, [sourceType]);

  // 签章的url 通过监听该url赋值情况来判断是否点了电子签章或者是已签名
  const [signUrl, setSignUrl] = useState(null);
  const {
    totalPage, // 全部页
    currentPage, // 当前页
    setTotalPage, // 设置全部全部页
    handleLoaded, // 设置pdf加载完成
    handleScrollTo, // 滚动上下
    pdfLoaded, // pdf加载状态
    pdfPageRef,
  } = usePdfPage(operationHeight);

  useEffect(() => {
    // 监听pdf且请求是否加载完成
    pdfLoaded && infoLoaded ? Toast.hide() : Toast.loading("PDF加载中", 0);
  }, [pdfLoaded, infoLoaded]);

  // 提交前的一些操作
  const handleOpenPop = async () => {
    if (!signUrl) return Toast.fail("请先完成签署");
    let { data } = await getByContractId(id)

    if (data && data.authStatus == -1) {
      Toast.show(`贵单位员工【${data.commitUser}】已经取消了本次企业认证，该授权书签署无效，已撤回`)
      setTimeout(() => {
        window.wx.miniProgram.redirectTo({
          url: '/pages/contractManage/contractList/index',
        });
      }, 2000)
      return
    }
    setCodeType(isSetPwd ? 2 : 1); // 如果设置了签署密码则优先显示签署密码否则显示手机验证码
    setPwdPop(true);
  };

  return (
    <div className="parent-wrapper-previous" style={{ paddingBottom: operationHeight }}>
      {/* 文档---用于最终生成pdf */}
      <PageView
        pdfPageRef={pdfPageRef}
        pdfUrl={pdfUrl}
        setTotalPage={setTotalPage}
        totalPage={totalPage}
        handleLoaded={handleLoaded}
        signUrl={signUrl}
        signUrlType={signUrlType}
        voList={voList}
        a4Rect={a4Rect}
        sealRect={sealRect}
        contractType={contractType}
      />
      {/* 操作栏 包含底部 分页 页码 */}
      <Operations
        totalPage={totalPage}
        currentPage={currentPage}
        handleScrollTo={handleScrollTo}
        setShowBoard={setShowBoard}
        setSignUrl={setSignUrl}
        setSignUrLType={setSignUrLType}
        sealList={sealList}
        realName={realName}
        handleOpenPop={handleOpenPop}
        operationHeight={operationHeight}
        userId={userId}
      />
      {/* 签字板 */}
      {showBoard && (
        <Board
          setSignUrl={setSignUrl}
          setSignUrLType={setSignUrLType}
          setShowBoard={setShowBoard}
        />
      )}
      <Modal
        popup
        visible={pwdPop}
        animationType="slide-up"
        maskClosable={false}
      >
        <PwdContent
          phone={phone}
          setPwdPop={setPwdPop}
          codeType={codeType}
          setCodeType={setCodeType}
          isSetPwd={isSetPwd}
          id={id}
          contractType={contractType}
          signUrl={signUrl}
          sealRect={sealRect}
          companyJoinVerifyId={companyJoinVerifyId}
        />
      </Modal>
    </div>
  );
};

export default SignBoard;
