import React, { useEffect, useState } from "react";
import boardTitleIcon from "../../../../assets/images/board_title.png";
import { useBoard } from "../../hooks/useBoard";
import "./index.less";

const Board = (props) => {
  const {
    setShowBoard, // 关闭画板
    setSignUrl, // 设置签字后生成的图片
    setSignUrLType, // 设置签章类型
  } = props;

  const {
    canvasContainerRef, // canvas父元素实例
    canvasWidth, // 通过canvasContainerRef获取的宽
    canvasHeight, // 通过canvasContainerRef获取的高
    rotateStatus, // 控制签字板旋转效果
    penColor, // 画笔颜色
    setPenColor, // 设置画笔颜色
    colorEnum, // 颜色枚举
    drawStart, // 画笔触及
    drawMove, // 画笔移动
    drawEnd, // 画笔提起
    clearDraw, // 清除画画
    sureDraw, // 确认画
    isDrew, // 是否动过笔
  } = useBoard();


  const [showMyToast, setShowMyToast] = useState(false)
  const sure = () => {
    if (!isDrew)
      return setShowMyToast(true)
    const base64 = sureDraw();
    setSignUrl(base64);
    setSignUrLType(2)
    setShowBoard(false);
  };



  // 简单应用于签字板提示 
  const MyToast = (props) => {
    const { title } = props
    const [showAnimate, setShowAnimate] = useState(false)
    useEffect(() => {
      setShowAnimate(true)
      setTimeout(() => setShowMyToast(false), 1500)
    }, [])
    return <div className="my-toast-mask">
      <div className={`my-toast ${showAnimate && "translate"}`}>{title}</div>
    </div>
  }

  return (
    <div
      id="board"
      className="board-comp"
      style={{
        width: window.innerHeight,
        height: window.innerWidth,
        transform: `translate(-50%, -50%) ${rotateStatus ? "rotate(90deg)" : ""
          }`,
      }}
    >
      {showMyToast && <MyToast title="请在指定位置写下签名" />}
      <img className="title-i" src={boardTitleIcon} alt="" />
      <div className="sign-area" ref={canvasContainerRef}>
        <canvas
          id="signDraw"
          width={canvasWidth}
          height={canvasHeight}
          onTouchStart={drawStart}
          onTouchMove={drawMove}
          onTouchEnd={drawEnd}
        />
      </div>
      <div className="operation-wrap">
        <div className="color-select">
          {colorEnum.map((item) => (
            <div
              key={item.color}
              className="color-box"
              onClick={() => setPenColor(item.color)}
            >
              {item.color === penColor ? item.checkedEl : item.checkEl}
            </div>
          ))}
        </div>
        <div className="btns">
          <span className="text-operation" onClick={() => setShowBoard(false)}>
            返回
          </span>
          <span className="text-operation" onClick={clearDraw}>
            清除
          </span>
          <button className="sure-btn" onClick={sure}>
            确定
          </button>
        </div>
      </div>
    </div>
  );
};

export default Board;
