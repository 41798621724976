import { useState, useEffect, useRef } from "react";
import checkedBlueIcon from "../../../assets/images/checked_blue.png";
import checkedRedIcon from "../../../assets/images/checked_red.png";

const colorEnum = [
  {
    color: "#363C5A",
    checkEl: <i className="check-i" style={{ background: "#363C5A" }} />,
    checkedEl: <img className="check-i" src={checkedBlueIcon} alt="" />,
  },
  {
    color: "#FF6060",
    checkEl: <i className="check-i" style={{ background: "#FF6060" }} />,
    checkedEl: <img className="check-i" src={checkedRedIcon} alt="" />,
  },
];

function useBoard() {
  const canvasContainerRef = useRef({}); // 获取到canvas父元素 - 宽和高赋值给canvas

  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);

  const [rotateStatus, setRotateStatus] = useState(false); // 控制签字板旋转效果

  const [penColor, setPenColor] = useState(colorEnum[0].color);

  const [canvas, setCanvas] = useState({});
  const [ctx, setCtx] = useState({});

  const [isDrew, setIsDrew] = useState(false);

  const [isDpr, setIsDpr] = useState(false)

  useEffect(() => {
    // 禁止页面滚动
    const fn = (e) => e.preventDefault();
    const board = document.getElementById("board");
    board.addEventListener("touchmove", fn, { passive: false });
    return () => board.removeEventListener("touchmove", fn, { passive: false });
  }, []);

  useEffect(() => {
    setRotateStatus(true); // 设置签字板旋转动态效果
    handleSetCanvas(); // 设置canvas
  }, []);

  // 监听画笔颜色
  useEffect(() => (ctx.strokeStyle = penColor), [penColor, ctx]);

  // 监听到ctx发生变化后为canvas配置dpr
  useEffect(() => {
    if (!ctx.scale || !canvas.width || !canvas.height) return;
    if (isDpr) return
    const { width, height } = canvas;
    const dpr = window.devicePixelRatio || 1;
    canvas.style.width = width + "px";
    canvas.style.height = height + "px";
    canvas.width = width * dpr;
    canvas.height = height * dpr;
    ctx.scale(dpr, dpr);
    setIsDpr(true)
  }, [ctx, canvas, isDpr]);

  // 设置canvas
  const handleSetCanvas = () => {
    const canvas = document.getElementById("signDraw");
    setCanvas(canvas);
    setCtx(canvas.getContext("2d") || {}); // 获取canvas上下文
    setCanvasWidth(canvasContainerRef.current.offsetWidth); // 设置canvas宽高
    setCanvasHeight(canvasContainerRef.current.offsetHeight);
  };

  // 获取canvas宽高
  const getCanvasXY = (e) => {
    const { width, left, top } = canvas.getBoundingClientRect();
    const { clientX, clientY } = e.touches[0];
    const x = width - (clientX - Math.round(left));
    const y = clientY - Math.round(top);
    return { x, y };
  };

  // 获取canvas宽高
  const getCanvasRect = () => {
    const { width, height } = canvas.getBoundingClientRect();
    return { width, height };
  };

  // 画笔触及
  const drawStart = (e) => {
    // 由于画板经过了90°旋转，所以xy为相反
    const { x, y } = getCanvasXY(e);
    ctx.beginPath();
    ctx.lineWidth = 4; // 线条粗细
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.moveTo(y, x);
  };

  // 画笔移动
  const drawMove = (e) => {
    setIsDrew(true); // 画笔移动后 证明用户已经写下签名

    // 由于画板经过了90°旋转，所以xy为相反
    const { x, y } = getCanvasXY(e);
    ctx.lineTo(y, x);
    ctx.stroke();
  };

  // 画笔提起
  const drawEnd = (e) => {
    ctx.closePath(); // 封笔
  };

  // 清除签名
  const clearDraw = () => {
    const { width, height } = getCanvasRect();
    ctx.clearRect(0, 0, height, width);
  };

  // 确认画
  const sureDraw = () => {
    return canvas.toDataURL("image/png");
  };

  return {
    canvasContainerRef, // canvas父元素实例
    canvasWidth, // 通过canvasContainerRef获取的宽
    canvasHeight, // 通过canvasContainerRef获取的高
    rotateStatus, // 控制签字板旋转效果
    penColor, // 画笔颜色
    setPenColor, // 设置画笔颜色
    colorEnum, // 颜色枚举
    drawStart, // 画笔触及
    drawMove, // 画笔移动
    drawEnd, // 画笔提起
    clearDraw, // 清除画画
    sureDraw, // 确认画
    isDrew, // 是否动过笔
  };
}

export { useBoard };
