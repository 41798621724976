import React from 'react'
import { Toast } from "antd-mobile";
import { isIos } from '../../utils'
import arrowUpIcon from '@/assets/images/icon_arrow_up.png'
import arrowDownIcon from '@/assets/images/icon_arrow_down.png'
import "./index.less"

const Operations = ({
	data: {
		pwdContentRef,
		signingList,
		userInfo,
		totalPage,
		currentPage,
		operationHeight,
	},
	operations: {
		setCodeType,
		handlePageChange,
	},
}) => {
	const isComplete = signingList?.every(it => it.signValue)

	const handleScrollPage = type => {
		const { 
			height: pageHeight 
		} = document
			.querySelector('#document-page-1')
			.getBoundingClientRect()
		const doScroll = type === 'up'
			? currentPage > 1
			: currentPage < totalPage

		doScroll && handlePageChange(type, pageHeight)
	}

	// 完成签署(提交)
  const handleFinish = async _ => {
    if (!isComplete) return Toast.fail("请先完成签署");
		
    setCodeType(userInfo?.isSecPwd ? 2 : 1); // 如果设置了签署密码则优先显示签署密码否则显示手机验证码
    pwdContentRef.current.open();
  };

	return <>
		<footer className="operations-wrappper" style={{ height: operationHeight }}>
			<div style={{ justifyContent: 'space-between' }} className={`operations-wrappper-sub flex-1 flex-center ${isIos() ? 'ios' : ''}`}>
				<div className='flex-1 flex-center'>
					<div
						className={`btn_page flex-center ${currentPage === 1 ? 'disabled' : ''}`}
						onClick={() => handleScrollPage('up')}
					>
						<img src={arrowUpIcon} className='icon-16' alt='' />
						<button className='ml-4'>上页</button>
					</div>
					<div
						className={`btn_page flex-center ${currentPage === totalPage ? 'disabled' : ''}`}
						onClick={() => handleScrollPage('down')}
					>
						<img src={arrowDownIcon} className='icon-16' alt='' />
						<button className='ml-4'>下页</button>
					</div>
				</div>
				<button className={`btn_done ${isComplete ? '' : 'disabled'}`} onClick={handleFinish}>完成签署</button>
			</div>
		</footer>
		<p className={`page-count ${isIos() ? 'ios' : ''}`}>{currentPage}/{totalPage}</p>
	</>
}

export default Operations;