import React, { useState } from 'react'
import SingleAutoFocusIpt from '@/components/SingleAutoFocusIpt'
import { formatPhone } from '@/utils/common'
import { sendCodeApi, signingApi, signingCompany, getByContractId } from '@/apis'
import { isBase64 } from '@/utils/common'
import { base64Upload } from '@/utils/base64Upload'
import './index.less'
import { Toast } from 'antd-mobile'

const PwdContent = (props) => {
    const {
        phone,
        setPwdPop,
        codeType,
        setCodeType,
        isSetPwd,
        id,
        contractType, // 4 是授权签署 5 划石社区合同
        signUrl,
        sealRect
    } = props

    const [codeTimer, setCodeTimer] = useState(0)

    const [phoneCodeFocus, setPhoneCodeFocus] = useState(false)

    const [pwdFulfill, setPwdFulfill] = useState(false)

    const [iptPwd, setIptPwd] = useState('')


    // 手机验证码输入
    const phoneCodeChange = ({ fulfill, value }) => {
        if (!fulfill) return
        signing({ code: value })
    }

    // 签署密码输入
    const signCodeChange = ({ fulfill, value }) => {
        setIptPwd(value)
        setPwdFulfill(fulfill)
    }

    // 获取验证码
    const sendCode = async () => {
        if (codeTimer > 0) return
        Toast.loading('验证码发送中', 0)
        await sendCodeApi(phone)
        setCodeTimer(60)
        const timer = setInterval(() => {
            setCodeTimer(prev => {
                if (prev === 1) {
                    clearInterval(timer)
                    return 0
                }
                return prev - 1
            })
        }, 1000)

        Toast.hide()
        setPhoneCodeFocus(true)
    }
    // 使用签署密码点击了确认
    const handlePwdSign = () => {
        if (!pwdFulfill) return
        signing({ pwd: iptPwd })

    }
    // 确认最终签署
    const signing = async (codeMap) => {
        let stampFile;
        Toast.loading('签署中', 0)

        // 判断是否是base64
        if (isBase64((signUrl || '').split(',')[1])) {
            stampFile = await base64Upload(signUrl)
        } else {
            stampFile = signUrl
        }

        let { data } = await getByContractId(id)
        if (data && data.authStatus == -1) {
            Toast.show(`贵单位员工【${data.commitUser}】已经取消了本次企业认证，该授权书签署无效，已撤回`)
            setTimeout(() => {
                window.wx.miniProgram.redirectTo({
                    url: '/pages/contractManage/contractList/index',
                });
            }, 2000)
            return
        }

        const form = {
            code: null,
            pwd: null,
            ...codeMap,
            contractId: id,
            width: sealRect.w,
            height: sealRect.h,
            stampId: null,
            stampFile
        }

        // contractType为 4 是签署授权书
        if (contractType == 4) {
            await signingCompany(form)
        } else {
            await signingApi(form)
        }

        Toast.hide()
        window.wx.miniProgram.redirectTo({
            url: `/subPackages1/pages/onlineSign/signResult/index?id=${id}&contractType=${contractType}`,
        });
    }

    // 前往设置签署密码
    const toSetPwd = () => {
        window.wx.miniProgram.navigateTo({ url: '/subPackages1/pages/securityCenterPages/setSignPwdStep1/index' })
    }

    return (<div className="pwd-content-previous">
        <div className="content-title">
            <span className="cancel" onClick={() => setPwdPop(false)}>取消</span>
            <h1>签署意愿验证</h1>
        </div>
        {codeType === 1 &&
            <>
                <div className="title">{formatPhone(phone)}</div>
                <h3 className="hint">请使用此手机号获取验证码</h3>
                <div className="ipt-wrap">
                    <SingleAutoFocusIpt
                        length="6"
                        type="number"
                        autoFocus={phoneCodeFocus}
                        codeChange={phoneCodeChange}
                    />
                </div>
                <div className="cut-type" onClick={() => setCodeType(2)}>使用签署密码签署</div>
                <button className="btn" style={{ opacity: codeTimer <= 0 ? 1 : 0.4 }} onClick={sendCode}>{codeTimer <= 0 ? "获取验证码" : "重新获取(" + codeTimer + "s)"}</button>
            </>
        }
        {codeType === 2 && (isSetPwd ?
            <>
                <div className="title">请输入签署密码</div>
                <div className="ipt-wrap">
                    <SingleAutoFocusIpt
                        length="6"
                        type="number"
                        codeChange={signCodeChange}
                    />
                </div>
                <div className="cut-type mt52" onClick={() => setCodeType(1)}>使用手机验证码签署</div>
                <button className="btn" onClick={handlePwdSign}>确认</button>
            </> :
            <>
                <div className="non-pwd-hint">请先设置签署密码再进行签署</div>
                <button className="to-set-btn" onClick={toSetPwd}>去设置</button>
                <div className="cut-type mt52" onClick={() => setCodeType(1)}>使用手机验证码签署</div>
            </>)
        }
    </div>)
}

export default PwdContent