import React, { useState } from 'react'
import { useDrawSeal } from './hooks/useDrawSeal'
import sealBgIcon from '../../assets/images/seal_bg.png'
import sealBackgroundIcon from '../../assets/images/seal_background.png'
import './index.less'

const Seal = (props) => {
    let { match: { params: { info } } } = props
    info = JSON.parse(info || '{}')
    info.token && (sessionStorage.token = info.token)

    const [sealDom, setSealDom] = useState({})
    useDrawSeal(sealDom, info.dev)
    // 不让 px2rem转换像素 所以涉及像素直接写内联
    return <div style={{ width: window.innerWidth, height: window.innerHeight }} className="seal-wrapper">
        <div className="seal-box" style={{ backgroundImage: `url(${sealBackgroundIcon})` }} >
            <div className="seal" ref={(dom) => setSealDom(dom)} style={{ width: '168px', height: '75px' }}>
                <img src={sealBgIcon} alt="" />
                <p className="name" style={{ fontSize: "24px", width: "104px" }}>{(info.name || '').split('').map((item, index) => <span key={index}>{item}</span>)}</p>
                <p className="id" style={{ marginTop: "5px", fontSize: "14px" }}>{info.id}</p>
            </div>
        </div>
    </div>
}
export default Seal