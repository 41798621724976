import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { 
  getContractInfo, 
  getWidgetInfo, 
  getSignInfo, 
  getSeal, 
  getUserInfo, 
} from "../../../apis/index";

function useSearchContract() {
  const { info } = useParams();
  const { 
    id: contractId, 
    token, 
    companyId,
    companyUserId,
    contractType, 
    companyJoinVerifyId, 
  } = JSON.parse(info);
  sessionStorage.token = token;
  sessionStorage.companyId = companyId;
  sessionStorage.companyUserId = companyUserId;

  const [documentList, setDocumentList] = useState([]); // PDF文档列表
  const [widgetList, setWidgetList] = useState([]); // 控件列表(所有)
  const [signedList, setSignedList] = useState([]); // 已签署控件列表
  const [signingList, setSigningList] = useState([]); // 待签署控件列表
  const [signInfo, setSignInfo] = useState({}); // 签署节点信息
  const [userInfo, setUserInfo] = useState({}); // 用户信息
  const [sealList, setSealList] = useState([]); // 印章列表
  const [sealErrorMessage, setSealErrorMessage] = useState(''); // 获取印章列表时错误信息
  const [infoLoaded, setInfoLoaded] = useState(false); // 所有接口是否获取成功

  // 获取合同文件列表
  const requestContract = useCallback(async () => {
    const { data } = await getContractInfo(contractId);
    setDocumentList(data)
  }, [contractId]);

  // 获取控件列表
  const requestWidget = useCallback(async () => {
    const { data } = await getWidgetInfo(contractId);
    const widgetList = data || [];
    setWidgetList(widgetList);
    setSignedList(widgetList?.filter(it => !it.currentSign));
    setSigningList(widgetList?.filter(it => it.currentSign));
  }, [contractId]);

  // 获取签署节点信息
  const requestSignInfo = useCallback(async () => {
    const { data } = await getSignInfo(contractId);
    const signInfo = data || {};
    setSignInfo(signInfo);
    return signInfo;
  }, [contractId]);

  // 根据签署类型(个人签名/企业盖章)获取印章列表
  const requestSeal = async type => {
    try {
      const { 
        data: sealList,
      } = await getSeal(type);
      setSealList(sealList || []);
    } catch({code, msg}) {
      code === '40011' && setSealErrorMessage(msg) // 无印章且无权限生成印章
    }
  };

  // 获取用户信息
  const requestUserInfo = async _ => {
    const {
      data: userInfo,
    } = await getUserInfo();
    setUserInfo(userInfo);
    return userInfo || {};
  };

  // 获取当前循环中页面的页码
  const getPageNum = (documentList, pageId) => {
    return []
      .concat(...documentList.map(it => it.imageList))
      .findIndex(it => it.id === pageId) 
      + 1
  }

  // 根据页面的文档信息计算页面的页码
  const calcPageNum = (documentList, documentId, documentPage) => {
    const targetIdx = documentList.findIndex(it => it.id === documentId)
    const target = documentList[targetIdx]
    let prevPageNum = 0
    for (let i = 0, len = documentList.length; i < len; i++) {
      if (i < targetIdx) {
        prevPageNum += target.imageList.length
      } else {
        prevPageNum += documentPage
        break;
      }
    }
    return prevPageNum
  }
  
  // 获取总计页码
  const getTotalPageNum = documentList => {
    return []
      .concat(...documentList.map(it => it.imageList))
      .length
  }

  useEffect(() => {
    const handleRequest = async () => {
      await Promise.all([
        requestContract(), 
        requestWidget(), 
        requestUserInfo(),
      ]);
      const { signatoryType } = await requestSignInfo()
      await requestSeal(signatoryType)
      setInfoLoaded(true);
    };

    handleRequest();
  }, [requestContract, requestSignInfo, requestWidget]);
  
  return {
    contractData: {
      documentList,
      widgetList,
      signedList,
      signingList,
      signInfo,
      userInfo,
      contractId,
      contractType, // 1 合同签署  4 授权签署  5 划石社区合同模板
      companyJoinVerifyId,
      sealList,
      sealErrorMessage,
      infoLoaded,
    },
    contractOperations: {
      setSigningList,
      getPageNum,
      calcPageNum,
      getTotalPageNum,
      requestSeal,
      requestUserInfo,
    },
  };
}

export { useSearchContract };
