import { useRef } from "react";
import { Modal, Toast } from "antd-mobile";
import html2canvas from 'html2canvas'
import { base64Upload } from '@/utils/base64Upload'
import { addSeal } from '@/apis'
import CloseIcon from '@/assets/images/icon_close.png'
import sealBgIcon from '@/assets/images/seal_bg.png'
import TipsIcon from '@/assets/images/icon_tips.png'
import "./index.less";

const CreateSeal = ({
  data: {
    signInfo,
    userInfo,
  },
  operations: {
    setShowCreateSeal,
    requestSeal,
  },
}) => {
  const sealRef = useRef({})

  const isEnterprise = signInfo.signatoryType === 2
  const typeText = isEnterprise ? '企业' : '个人'

  const handleCreateSeal = () => {
    html2canvas(sealRef.current, {
      allowTaint: true,
      logging: true,
      dpi: 350,
      backgroundColor: "transparent"
    }).then(async canvas => {
        const imgBase64 = canvas.toDataURL('image/png');
        const url = await base64Upload(imgBase64)
        await addSeal(url)
        handleClose()
        Toast.success('生成成功', 1.5)
        requestSeal(signInfo?.signatoryType) // 重新获取印章
    })
  }

  const handleClose = _ => setShowCreateSeal(false)

  return (
    <Modal
      visible={true}
      maskClosable={false}
      wrapClassName="create-seal-modal-wrapper"
      transparent
    >
      <section className="create-seal-modal flex-c">
        <img src={CloseIcon} onClick={handleClose} className="icon-24 icon-close" alt="" />

        <div className="title flex-center">
          生成{typeText}印章
        </div>

        <div className="content flex-c mt-16">
          <div ref={sealRef} style={{ width: '168px', height: '80px' }} className="seal flex-c-c">
              <img src={sealBgIcon} className="icon-seal-bg" alt="" />
              <p style={{ justifyContent: 'space-between', width: "104px", fontSize: "24px", lineHeight: 1 }} className="name flex-center">
                {
                  (userInfo?.userName || '-')
                    .split('')
                    .map((item, index) => 
                      <span key={index}>{item}</span>
                    )
                }
              </p>
              <p style={{ marginTop: "5px", fontSize: "14px" }} className="id">
                {userInfo?.userSerial || '-'}
              </p>
          </div>

          <span className="desc mt-16">{typeText}印章暂只支持中文姓名，且姓名字数在4个字以内</span>
        </div>

        <button onClick={handleCreateSeal} className="btn-create mt-16">立即生成</button>

        <article className="tips flex-c mt-16">
          <div className="flex-center">
            <img src={TipsIcon} className="icon-16" alt="" />
            <span className="ml-4">小知识</span>
          </div>
          <p className="mt-4">电子印章是电子数据表现形式的公章和具有法律效力的个人名章，可在电子证照、电子合同、电子发票等各类电子文件上加盖或签章；</p>
          <p className="mt-4">电子印章以密码技术为核心，将数字证书、签名密钥与印章图像有效绑；</p>
          <p className="mt-4">可解决同一印章异地使用难的问题，具有足不出户、无接触即可完成线上签约的优势。</p>
        </article>
      </section>
    </Modal>
  );
};

export default CreateSeal;
