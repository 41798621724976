import { useState, useMemo, useRef } from 'react'

function usePdfPage(operationHeight) {
    const [totalPage, setTotalPage] = useState(0); // 总条数 通过Document获取
    const [scrollTop, setScrolltop] = useState(0) // 页面滚动距离
    const [pdfLoaded, setPdfLoaded] = useState(false) // pdf是否加载完成

    const pdfPageRef = useRef({}) // 用户获取pdf组件高度 计算页码


    const handleLoaded = () => setPdfLoaded(true) // 设置加载完成

    // 监听页面滚动
    window.onscroll = () => {
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
        setScrolltop(scrollTop)
    }

    const currentPage = useMemo(() => { //通过滚动计算页码
        if (!pdfLoaded) return 0
        const singlePageHeight = pdfPageRef.current.offsetHeight / totalPage // 组件总高度除以页码 即单页的高度
        if (scrollTop === 0) return 1
        return Math.round((scrollTop + window.innerHeight - operationHeight) / singlePageHeight)
    }, [scrollTop, totalPage, pdfLoaded, operationHeight])

    const handleScrollTo = (type) => { // 控制页面回到底部或顶部
        const topEnum = {
            'up': 0,
            'down': document.body.offsetHeight
        }
        window.scrollTo({
            top: topEnum[type],
            behavior: 'smooth'
        })
    }

    return {
        totalPage, // 全部页
        currentPage, // 当前页
        setTotalPage, // 设置全部全部页
        handleLoaded, // 设置pdf加载完成
        handleScrollTo, // 滚动上下
        pdfLoaded, // pdf加载状态
        pdfPageRef // pdf组件盒子实例  用于获取到组件总高度 
    }
}

export { usePdfPage }