import {
	forwardRef,
	useImperativeHandle,
	useState,
} from 'react'
import { Toast, Modal } from 'antd-mobile'
import SingleAutoFocusIpt from '@/components/SingleAutoFocusIpt'
import { formatPhone, isBase64 } from '@/utils/common'
import { base64Upload } from '@/utils/base64Upload'
import { 
	sendCodeApi, 
	submiSign,
} from '@/apis'
import './index.less'

const PwdContent = forwardRef((
	{
		data: {
			contractId,
			signingList,
			signInfo,
			userInfo,
			codeType,
			contractType, // 4 是授权签署 5 划石社区合同
		},
		operations: {
			setCodeType,
			requestUserInfo,
		},
	},
	ref,
) => {
	const [visible, setVisible] = useState(false)
	const [codeTimer, setCodeTimer] = useState(0)
	const [phoneCodeFocus, setPhoneCodeFocus] = useState(false)
	const [pwdFulfill, setPwdFulfill] = useState(false)
	const [iptPwd, setIptPwd] = useState('')

	const isEnterprise = signInfo.signatoryType === 2

	useImperativeHandle(
    ref,
    () => ({
      open: async () => {
        setVisible(true)
      },
      close: _ => handleClose(),
    }),
  )

  const handleClose = _ => {
    setVisible(false)
  }

	// 手机验证码输入
	const phoneCodeChange = ({ fulfill, value }) => {
		if (!fulfill) return
		signing({ code: value })
	}

	// 签署密码输入
	const signCodeChange = ({ fulfill, value }) => {
		setIptPwd(value)
		setPwdFulfill(fulfill)
	}

	// 获取验证码
	const sendCode = async _ => {
		if (codeTimer > 0) return
		Toast.loading('验证码发送中', 0)
		await sendCodeApi(userInfo?.phone)
		setCodeTimer(60)
		const timer = setInterval(() => {
			setCodeTimer(prev => {
				if (prev === 1) {
					clearInterval(timer)
					return 0
				}
				return prev - 1
			})
		}, 1000)

		Toast.hide()
		setPhoneCodeFocus(true)
	}
	// 使用签署密码点击了确认
	const handlePwdSign = _ => {
		if (!pwdFulfill) return
		signing({ pwd: iptPwd })
	}
	// 确认最终签署
	const signing = async codeMap => {
		Toast.loading('签署中', 0)

		const valuelist = await Promise.all(
			signingList
				.map(it => 
					isBase64((it.signValue).split(',')[1]) ? 
						base64Upload(it.signValue) :
						Promise.resolve(it.signValue)
				)
		)

		const form = {
			contractId,
			code: null,
			pwd: null,
			stampList: signingList.map((
				{
					id: widgetId,
					stampId,
				},
				idx,
			) => ({
				signValue: valuelist[idx],
				widgetId,
				stampId,
			})),
			...codeMap,
		}

		const { 
			data: companyId,
		} = await submiSign(form)

		Toast.hide()
		Toast.success('签署成功', 1.5)
		setTimeout(() => {
			window.wx.miniProgram.redirectTo({
				url: `/subPackages4/pages/signSuccess/signSuccess?id=${contractId}&signByMe=${companyId ? 1 : 2}&companyId=${companyId}`,
			});
		}, 1500);
	}

	// 前往设置签署密码
	const toSetPwd = _ => {
		window.wx.miniProgram.navigateTo({ 
			url: '/subPackages1/pages/securityCenterPages/setSignPwdStep1/index', 
		})
	}

	const handlePwdConfirm = async _ => {
		const { isSecPwd } = await requestUserInfo()
		if (!isSecPwd) {
			Toast.info('检测到您尚未设置签署密码，请点击【去设置】前往设置')
		}
	}

	return (
		<Modal
			visible={visible}
			popup
			animationType="slide-up"
			maskClosable={false}
			wrapClassName="pwd-modal"
		>
			<div className="pwd-content">
				<div className="content-title">
					<span className="cancel" onClick={handleClose}>取消</span>
					<h1>签署意愿验证</h1>
				</div>
				{codeType === 1 &&
					<>
						<div className="title">{formatPhone(userInfo?.phone)}</div>
						<h3 className="hint">请使用此手机号获取验证码</h3>
						<div className="ipt-wrap">
							<SingleAutoFocusIpt
								length="6"
								type="number"
								autoFocus={phoneCodeFocus}
								codeChange={phoneCodeChange}
								scrollWhenFocus={false}
							/>
						</div>
						<div className="cut-type" onClick={() => setCodeType(2)}>使用签署密码签署</div>
						<button className="btn" style={{ opacity: codeTimer <= 0 ? 1 : 0.4 }} onClick={sendCode}>{codeTimer <= 0 ? "获取验证码" : "重新获取(" + codeTimer + "s)"}</button>
					</>
				}
				{codeType === 2 && (userInfo?.isSecPwd ?
					<>
						<div className="title">请输入签署密码</div>
						<div className="ipt-wrap">
							<SingleAutoFocusIpt
								length="6"
								type="number"
								codeChange={signCodeChange}
								scrollWhenFocus={false}
								sensitive
							/>
						</div>
						<div className="cut-type mt52" onClick={() => setCodeType(1)}>使用手机验证码签署</div>
						<button className="btn" onClick={handlePwdSign}>确认</button>
					</> :
					<>
						<div className="non-pwd-hint">请先设置签署密码再进行签署</div>
						<div style={{justifyContent: 'space-evenly'}} className='flex-center'>
							<button className="to-set-btn confirm" onClick={handlePwdConfirm}>已设置密码，去签署</button>
							<button className="to-set-btn" onClick={toSetPwd}>去设置</button>
						</div>
						<div className="cut-type mt52" onClick={() => setCodeType(1)}>使用手机验证码签署</div>
					</>)
				}
			</div>
		</Modal>
	)
})

export default PwdContent