import React, { useState } from "react";
import { useFAQList } from "./hooks/useFAQList";
import { Accordion } from "antd-mobile";
import "./index.less"; 

const FAQ = () => {
  const tabList = [
    {
      text: "登录与认证",
      value: "1",
    },
    {
      text: "合同签署",
      value: "2",
    },
    {
      text: "电子合同小知识",
      value: "3",
    },
    
  ];

  document.title = "常见问题";

  const [activeTab, setActiveTab] = useState("1");

  const { FAQList } = useFAQList(activeTab);

  return (
    <div className="faq-wrapper">
      <div className="tab-list">
        {tabList.map((item) => (
          <div
            className={`tab-item ${item.value === activeTab && "active"}`}
            key={item.value}
            onClick={() => setActiveTab(item.value)}
          >
            {item.text}
          </div>
        ))}
      </div>
      <div className="list-wrap">
        <Accordion defaultActiveKey="0">
          {FAQList.map((item, index) => (
            <Accordion.Panel key={index} header={item.title}>
              <div
                className="content-wrap"
                dangerouslySetInnerHTML={{ __html: item.content }}
              ></div>
            </Accordion.Panel>
          ))}
        </Accordion>
      </div>
    </div>
  );
};
export default FAQ;
