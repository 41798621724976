import { 
  createContext, 
  useState, 
  useEffect, 
  useRef, 
} from 'react'
import { useSearchContract } from "./hooks/useSearchContract";
import { usePdfPage } from "./hooks/usePdfPage";
import { ENV } from "@/config";
import { isIos } from './utils'

const operationHeight = isIos() ? 90 : 56; // 底部控件的高度 prev -> (83 / 375) * window.innerWidth;

const SignBoardContext = createContext()

const DataProvider = ({children}) => {
  const sealSignRef = useRef({}); // 侧边栏-个人/企业 印章
  const boardRef = useRef({}); // 侧边栏-签字板
  const dateSignRef = useRef({}); // 侧边栏-签署日期
  const pwdContentRef = useRef({}); // 底部栏-完成签署
  const [codeType, setCodeType] = useState(2); // 1-验证码签署 2-签署密码签署
  const [showQuickSign, setShowQuickSign] = useState(false); // 打开或关闭一键签署
  const [showCreateSeal, setShowCreateSeal] = useState(false); // 打开或关闭生成印章
  const [a4Rect, setA4Rect] = useState({}); // a4大小 通过该大小根据不同手机按比例定位
  
  const {
    pdfData,
    pdfOperations,
  } = usePdfPage(operationHeight);
    
  const {
    contractData,
    contractOperations,
  } = useSearchContract();

  useEffect(() => {
    const rect = ENV === "test1" 
      || ENV === "test2" 
        ? { w: 793, h: 1122 }
        : { w: 793, h: 1122 }
        // : { w: 595, h: 842 }

      setA4Rect(rect);
  }, [setA4Rect, contractData.sourceType]);
  
  const _commonData = {
    sealSignRef,
    boardRef,
    dateSignRef,
    pwdContentRef,
    codeType,
    a4Rect,
    showQuickSign,
    showCreateSeal,
    operationHeight,
    ...pdfData,
    ...contractData,
  }

  const _commonOperations = {
    setShowQuickSign,
    setShowCreateSeal,
    setA4Rect,
    setCodeType,
    ...pdfOperations,
    ...contractOperations,
  }

  const value = {
    _commonData,
    _commonOperations,
  }

  return <SignBoardContext.Provider value={value}>
    {children}
  </SignBoardContext.Provider>
}

export {
  DataProvider,
  SignBoardContext,
}