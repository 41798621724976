import React from 'react'
import logoIcon from '../../assets/images/logo.png'
import './index.less'

const ContactUs = () => {
    return <div className="contact-wrap" style={{ minHeight: window.innerHeight + 'px' }}>
        <img className="logo-i" src={logoIcon} alt="" style={{borderRadius:'16px'}} />
        <h1 className="title">签约宝</h1>
        <div className="call">zhili@uoko.com</div>
        <p className="hint">如有任何问题，欢迎与我们取得联系。</p>
    </div>
}

export default ContactUs
