import React from 'react'
import { useHistory } from 'react-router-dom'
import { Modal } from 'antd-mobile'
import arrowIcon from '../../../../assets/images/arrow.png'
import "./index.less"

const alert = Modal.alert

const PageView = (props) => {
    const history = useHistory()

    const {
        totalPage,
        currentPage,
        handleScrollTo,
        setShowBoard,
        setSignUrl,
        sealList,
        realName,
        setSignUrLType,
        handleOpenPop,
        operationHeight,
        userId
    } = props

    const handleSeal = () => {
        if (realName?.length > 4 || !((/^[\u4E00-\u9FA5]+$/).test(realName))) {
            return alert('提示', '暂不支持中文名字超过4个字或非中文名字的用户生成印章', [
                { text: '知道了' },
            ])
        }


        // 用户点击了生成印章
        const confirm = () => {
            const info = JSON.stringify({
                name: realName,
                id: userId,
                dev: 'web'
            })
            history.push('/seal/' + info)
        }

        if (!sealList.length) return alert('提示', '您尚未设置个人电子印章，是否立即生成印章？', [
            { text: '现在不用' },
            { text: '立即生成', onPress: () => confirm() },
        ])
        setSignUrl(sealList[0]?.stampFile)
        setSignUrLType(1)
    }


    return <>
        <div className="operations-wrappper-previous" style={{ height: operationHeight }}>
            <div className="operations">
                <button className="white-btn btn" onClick={handleSeal}>电子印章</button>
                <i className="line" />
                <button className="white-btn btn" onClick={() => setShowBoard(true)}>手写签名</button>
                <button className="blue-btn btn" onClick={handleOpenPop}>提交</button>
            </div>
        </div>
        <p className="page-count-previous">{currentPage}/{totalPage}</p>
        {
            currentPage > 1
            &&
            <img
                className="arrow up"
                onClick={() => handleScrollTo('up')}
                src={arrowIcon}
                alt=""
            />
        }
        {
            currentPage < totalPage
            &&
            <img
                className="arrow down"
                onClick={() => handleScrollTo('down')}
                style={{ transform: "rotate(180deg)" }}
                src={arrowIcon} alt=""
            />
        }
    </>
}

export default PageView;