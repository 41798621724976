import React, { useEffect, useContext } from "react";
import { Toast } from "antd-mobile";
import {
  SignBoardContext,
  DataProvider,
} from './context'
import PageView from "./components/PageView";
import SideBar from "./components/SideBar";
import QuickSign from "./components/QuickSign";
import SealSign from "./components/SealSign";
import CreateSeal from "./components/CreateSeal";
import DateSign from "./components/DateSign";
import Operations from "./components/Operations";
import Board from "./components/Board";
import PwdContent from "./components/PwdContent";
import "./index.less";

const SignBoard = _ => {
  const {
    _commonData: {
      sealSignRef,
      boardRef,
      dateSignRef,
      pwdContentRef,
      showQuickSign,
      showCreateSeal,
      operationHeight,
      infoLoaded,
      pdfLoaded,
    },
    _commonData,
    _commonOperations,
  } = useContext(SignBoardContext)

  // 监听pdf且请求是否加载完成
  useEffect(() => {
    pdfLoaded && infoLoaded 
      ? Toast.hide() 
      : Toast.loading("PDF加载中", 0);
  }, [pdfLoaded, infoLoaded]);

  const commonProps = {
    data: _commonData,
    operations: _commonOperations,
  }

  return (
    <div className="parent-wrapper" style={{ paddingBottom: operationHeight }}>
      {/* 文档---用于最终生成pdf */}
      <PageView {...commonProps} />
      {/* 侧边操作栏 包含各项快捷签署操作 */}
      <SideBar {...commonProps} />
      {/* 底部操作栏 包含完成签署 分页 页码 */}
      <Operations {...commonProps} />
      {/* 签字板 */}
      <Board ref={boardRef} {...commonProps} />
      {/* 侧边操作栏-一键签署弹窗 */}
      {showQuickSign && (
        <QuickSign {...commonProps} />
      )}
      {/* 侧边操作栏-个人/企业 印章弹窗 */}
      <SealSign ref={sealSignRef} {...commonProps} />
      {/* 侧边操作栏-生成印章弹窗 */}
      {showCreateSeal && (
        <CreateSeal {...commonProps} />
      )}
      {/* 侧边操作栏-签署日期弹窗 */}
      <DateSign ref={dateSignRef} {...commonProps} />
      {/* 底部操作栏-完成签署弹窗 */}
      <PwdContent ref={pwdContentRef} {...commonProps} />
    </div>
  );
};

const SignBoardWrapper = _ => (
  <DataProvider>
    <SignBoard />
  </DataProvider>
)

export default SignBoardWrapper;
