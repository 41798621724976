import request from '../utils/request'

// 获取合同pdf的信息
export const getContractDetail = (id) => request.get(`/api/remote_signing/uoko_signing/measuringPDF/${id}`)

// 获取用户实名认证信息
export const getUserInfo = (phone) => request.post('/api/remote_signing/queryByUserInfo', { outCompanyId: null, phone })

// 获取印章
export const getSeal = () => request.get(`/api/remote_signing/user_stamp/getList`)

// 添加印章
export const addSeal = (url) => request.post('/api/remote_signing/user_stamp/addStamp', { stampFile: url })

// 获取验证码
export const sendCodeApi = (phone) => request.get(`/api/remote_signing/redis_code/sendCode/${phone}`)

// 校验验证码
export const verifyCodeApi = (form) => request.post(`/api/remote_signing/redis_code/verifyCode`, form)

// 确认签署 签署租赁合同
export const signingApi = (form) => request.post(`/api/remote_signing/uoko_signing/signingUokoIterate`, form)

// 根据审核id 获取审核信息
export const join = (id) => request.get(`/api/remote_signing/company/join/getById?id=${id}`)

// 确认签署 签署入驻授权书
export const signingCompany = (data) => request.post('/api/remote_signing/uoko_signing/signingCompanyAuthorization', data)

// 查询合同状态
export const contractInfoById = (id) => request.get(`/api/remote_signing/contract_report/queryOlineContractInfoById/${id}`)

// 根据合同id获取审核信息
export const getByContractId = (id) => request.get(`/api/remote_signing/company/join/getByContractId?contractId=${id}`)

// 根据流水号查询用户实名认证数据
export const getUserAuth = (responseCode) => request.get(`/api/remote_signing/api/auth/findVerifyInfo/${responseCode}`)

// 取消认证
export const cancelVerifyApi = (responseCode) => request.get(`/api/remote_signing/ocr_rpc/cancelResponseCode/${responseCode}`)

// 获取人脸识别结果
export const faceResult = (responseCode) => request.get(`/api/remote_signing/face/getFaceBaiduDetail/${responseCode}`)
