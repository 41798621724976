import React, { useState, useEffect, useContext } from "react";
import { Picker } from "antd-mobile";
import { SignBoardContext } from '../../context'
import ArrowIcon from '@/assets/images/right_icon.png'
import "./index.less";

const Header = props => {
  const {
    _commonOperations: {
      handleScrollTo,
    },
  } = useContext(SignBoardContext)

  const {
    documentList = [],
    signInfo: {
      documentNum, // 主文件数量
      documentAppendix, // 附件数量
    } = {},
  } = props;

  const [pickerValue, setPickerValue] = useState([])

  useEffect(() => {
    if (documentList?.length) {
      setPickerValue([documentList[0]?.id])
    }
  }, [documentList])

  // 选择确认后跳转到对应的文档首页
  const handleConfirm = ([documentId]) => {
    const { 
			height: pageHeight,
		} = document
			.querySelector('#document-page-1')
			.getBoundingClientRect()

    const scrollHeight = []
      .concat(...documentList.map(it => it.imageList))
      .findIndex(it => it.documentId === documentId)
      * pageHeight

    handleScrollTo(scrollHeight)
    setPickerValue([documentId])
  }

  return (
    <header className="sign-header flex-c">
      <section style={{paddingBottom: 12, borderBottom: '1px solid rgba(0, 0, 0, .1)'}} className="flex-c">
        <div style={{color: '#363C5A', fontSize: 14}}>
          当前待签署文件共有 
          <span style={{marginInline: 4, color: '#609BFF'}}>
            {documentList.length}
          </span>
          个 
        </div>
        <div style={{marginTop: 4, color: 'rgba(54, 60, 90, .6)', fontSize: 12}}>
          其中 {documentNum ?? '-'} 个主文件需签字 {documentAppendix ? `, ${documentAppendix ?? '-'}个文件为附件` : ''} 
        </div>
      </section>

      <Picker
        data={documentList.map(it => ({label: it.documentName, value: it.id }))}
        value={pickerValue}
        cols={1}
        onOk={handleConfirm}
      >
        <section className="mt-12 flex-center">
          <div 
            className="flex-1 text-ellipse"
            style={{
              color: '#363C5A', 
              fontWeight: 'bold', 
              fontSize: 16,
            }}
          >
            {
              documentList
                ?.find(it => it.id === pickerValue[0])
                ?.documentName 
                || '-'
            }
          </div>
          <img src={ArrowIcon} className="icon-16 ml-4" alt="" />
        </section>
      </Picker>
    </header>
  );
};

export default Header;
